@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"); */
/*font-family: 'Roboto Slab', serif; */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"); */
/*font-family: 'Open Sans', sans-serif;*/

/* Global CSS */

body {
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  scroll-padding-top: 200px;
}

iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}

.c-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.link-text {
  color: #e26640;
  font-weight: 600;
}

.link-text:hover {
  color: #e26640;
}

.link-blue-text {
  color: #25429c;
  font-weight: 500;
  cursor: pointer;
}

.link-blue-text:hover {
  color: #25429c;
}

.link-orange-text {
  color: #e26640;
  cursor: pointer;
}

.link-orange-text:hover {
  color: #e26640;
}

.hide {
  display: none;
}

fieldset[disabled] {
  pointer-events: none;
}

#incubatorstepper {
  position: relative;
}

.bs-stepper-header {
  position: -webkit-sticky;
  position: sticky;
  height: 120px;
  background-color: #fff;
  z-index: 99;
  margin-bottom: 10px;
}

.bs-stepper-header {
  top: 95px;
}

#reviewstepper .bs-stepper-header {
  top: 95px;
}

.hidden {
  display: none !important;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.navbar-toggler {
  background-color: inherit !important;
  color: inherit !important;
  border: 1px solid inherit !important;
}

.btn-group .btn-sisfs+.btn-sisfs {
  margin-left: 15px !important;
}

.btn-sisfs {
  border-radius: 20px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 22px !important;
  padding: 0.375rem 2rem !important;
}

.subsqnt .btn-sisfs {
  border-radius: 20px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  line-height: 18px !important;
  padding: 0.36rem 1.4rem !important;
}



.objectives-nav .btn-sisfs {
  border-radius: 20px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 18px !important;
  padding: 0.375rem 2rem !important;
}

.approve-btn {
  cursor: pointer;
  padding: 8px 9px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
  background-color: #60ab55;
  border-radius: 20px !important;
}

.reject-btn {
  cursor: pointer;
  padding: 8px 9px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
  background-color: red;
  border-radius: 20px !important;
}

.portfolio-btn {
  cursor: pointer;
  padding: 8px 9px;
  font-size: 14px;
  font-weight: 600;
  border: none;
  color: white;
  background-color: #25429c;
  border-radius: 20px !important;
}

#scheduleMeeting .btn-sisfs {
  height: 100%;
}

#filterModal .btn-sisfs {
  height: 40px;
}

.btn-apply {
  height: 18px;
  width: 75px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-align: center;
}

.btn-large.btn-sisfs {
  padding: 12px 3rem !important;
  font-size: 16px !important;
  line-height: 22px;
  font-weight: 700;
  border-radius: 25px !important;
}

.contact-head {
  font-weight: 700;
  font-size: 22px;
}

.btn-transparent-primary {
  background-color: #ffffff !important;
  border: 1px solid #25429c !important;
  color: #25429c !important;
}

.btn-transparent-primary_grievance {
  background-color: #f8d874 !important;
  border: 1px solid #f8d874 !important;
  color: #25429c !important;
}

.btn-yellow-primary {
  background-color: #f8d874 !important;
  border: 1px solid #f8d874 !important;
  color: #25429c !important;
}

.btn-blue-primary {
  background-color: #25429c !important;
  border: 1px solid #25429c !important;
  color: #ffffff !important;
}

.btn_Orange {
  background-color: #ffa50080 !important;
  border: 1px solid #ffa50080 !important;
  color: #25429c !important;
}

.btn_green {
  background-color: #68e56880 !important;
  border: 1px solid #68e56880 !important;
  color: #25429c !important;
}

.btn_green_opacity {
  background-color: #68e56880 !important;
  border: 1px solid #68e56880 !important;
  color: #25429c !important;
  opacity: 0.6;
}

.pdfText {
  color: #25429c;
  font-size: 14px !important;
  font-style: normal;
  font-family: "Open Sans", sans-serif !important;
  scroll-padding-top: 200px;
  margin-bottom: 15px;
}

.orange-link-text {
  text-decoration: underline;
  color: #e26640;
  font-size: 14px !important;
  font-style: normal;
  font-family: "Open Sans", sans-serif !important;
}

.orange-link-text:hover {
  color: #e26640;
}

.arrow-link {
  position: relative;
  /* display: block; */
  /* padding: 1em 3em 1em 1em;
    font-size: 30px; */
  /* font-weight: bold; */
  /* text-decoration: none; */
  /* color: #e74c3c;
    box-shadow: inset 0 0 0 0.2em #e74c3c; */
  background-color: rgba(226, 102, 64, 0.63);
  color: #ffffff;
  padding: 2px 20px 2px 10px;
  font-size: 8px;
  line-height: 12px;
  display: inline-block;
}

.arrow-link:after,
.arrow-link:before {
  content: "";
  display: block;
  position: absolute;
  transition: all 0.5s ease-in-out;
}

.arrow-link:before {
  top: 50%;
  right: 1em;
  width: 1em;
  height: 1em;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: translate(0, -0.5em) rotate(5deg);
          transform: translate(0, -0.5em) rotate(5deg);
  /* box-shadow: inset -0.2em 0.2em 0 0 #e74c3c; */
  /* border-radius: 0 0.15em 0 0;  */
  box-shadow: inset -0.2em 0.2em 0 0 #ffffff;
}

.arrow-link:after {
  top: 50%;
  right: 1em;
  width: 1.2em;
  height: 0.2em;
  -webkit-transform: translate(0, -0.1em) rotate(-40deg);
          transform: translate(0, -0.1em) rotate(-40deg);
  /* background-color: #e74c3c; */
  background-color: #ffffff;
}

/* Global CSS */

/*  */
.form-group .form-control::-webkit-input-placeholder {
  color: #777777 !important;
  font-size: 15px;
}

/* WebKit, Blink, Edge */
.form-group .form-control:-moz-placeholder {
  color: #777777;
  font-size: 15px;
}

/* Mozilla Firefox 4 to 18 */
.form-group .form-control::-moz-placeholder {
  color: #777777;
  font-size: 15px;
}

/* Mozilla Firefox 19+ */
.form-group .form-control:-ms-input-placeholder {
  color: #777777;
  font-size: 15px;
}

/* Internet Explorer 10-11 */
.form-group .form-control::-ms-input-placeholder {
  color: #777777;
  font-size: 15px;
}

/* Microsoft Edge */

/* Modal CSS */
.modal-sisfs .modal-body {
  padding: 40px 40px;
}

.modal-sisfs .modal-body .modal-action {
  margin: 20px 10px;
}

.no_button {
  color: #FFF !important;
}

.modal-sisfs .modal-body .modal-message {
  font-size: 28px;
  font-family: "Roboto Slab", serif;
  line-height: 40px;
  text-align: center;
  color: #25429c;
}

.modal-sisfs .modal-body .modal-text {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

/* Modal CSS */

/* Custom Multi-select CSS */
.ms-wrapper .form-select {
  position: relative;
}

.ms-wrapper .form-select .arrow {
  position: absolute;
  right: 10px;
  top: 15px;
}

.ms-wrapper .ms-dropdown {
  background: #fff;
  padding: 15px 20px;
  width: 100%;
  position: absolute;
  z-index: 999;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #ddd;
  width: 100%;
}

.ms-wrapper .ms-dropdown ul {
  padding-left: 0px !important;
  margin: 0px !important;
}

.ms-wrapper .ms-dropdown ul li {
  padding: 5px 0px !important;
}

.ms-wrapper .ms-dropdown ul li input {
  margin-right: 10px;
}

.ms-wrapper .ms-dropdown ul li label {
  margin-bottom: 0px;
}

.ms-wrapper .pills {
  /* border: 1px solid #B7BBC2; */
  padding: 10px 10px;
  /* max-height: 300px;
  overflow-y: scroll; */
}

.pills .pill {
  padding: 4px 10px;
  display: inline-block;
  margin: 5px;
  border-radius: 50px;
  font-size: 12px;
  line-height: 1.5;
  /* background-color: #D6DFF2; */
  border: 1px solid #d0d4e0;
  /* border-radius: 35.5px; */
  background-color: #f4f4f4;
  word-break: break-all;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 30px;
  color: #333333;
}

.pills .pill .pill-close {
  position: absolute;
  right: 10px;
  font-size: 14px;
  color: #777777;
  opacity: 1;
  top: 5px;
}

.arrow {
  border: solid #999999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.form-wrapper .form-group .multi-select {
  width: 100%;
}

.stepper-form .form-group .multi-select {
  width: 65%;
}

.stepper-form .ms-wrapper .ms-dropdown {
  width: 65%;
}

.ms-wrapper .ms-dropdown ul .form-control {
  padding: 7px 0.75rem 7px 30px;
  margin-bottom: 10px;
}

.ms-wrapper .ms-dropdown ul .search-wrap {
  position: relative;
}

.ms-wrapper .ms-dropdown ul .search-icon {
  background-image: url(/static/media/search.e79ae1ef.svg);
  position: absolute;
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 20px;
  height: 20px;
  top: 10px;
  left: 7px;
  cursor: pointer;
}

/* Custom Multi-select CSS */

/* Custom select CSS */
.select-custom select {
  /* Hide default arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.select-custom {
  position: relative;
}

.select-custom::after {
  content: "";
  position: absolute;
  right: 17px;
  top: 17px;
  border: solid #999999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Custom select CSS */

/* Custom Radio and Checkbox CSS */
.form-group .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #25429c !important;
  background-color: #25429c !important;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #25429c !important;
  background-color: #25429c !important;
}

.custom-control-label::before {
  border: #25429c solid 1px !important;
}

.dropdown-disabled::after {
  display: none;
}

/* Custom Radio and Checkbox CSS */

/* Main Navigation CSS */
.navbar-nav .nav-item>.nav-link.active {
  border-bottom: 4px solid #e26640;
  color: rgb(85, 85, 85);
}

.navbar .navbar-nav>li.dropdown:hover>a,
.navbar .navbar-nav>li.dropdown:hover>a:hover,
.navbar .navbar-nav>li.dropdown:hover>a:focus {
  /* background-color: rgb(231, 231, 231); */
  /* border-bottom: 4px solid #e26640; */
  color: rgb(85, 85, 85);
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
}

.app-header .navbar-collapse .dropdown-menu {
  top: 85%;
  left: -75%;
  box-shadow: 0 1px 5px 0 rgba(191, 196, 201, 0.84);
  border: none;
  /* padding: 0px; */
  min-width: 300px;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item:focus,
.app-header .navbar-collapse .dropdown-menu .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  /* background-color: #e9ecef; */
  background-color: transparent;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item {
  padding: 0px 25px;
  white-space: inherit;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item .myapp {
  background-color: #000000;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item:first-child a {
  border-top: 0px;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item a {
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  padding: 15px 5px;
  display: block;

  /* border-top: 1px solid #e0e0e0; */
  text-transform: capitalize;
  font-weight: 600;
}

.app-header .navbar-collapse .dropdown-menu .dropdown-item a:hover {
  color: #e26640 !important;
  text-decoration: none;
}

/* Main Navigation CSS */

/* Header Profile Section CSS */
.app-profile-section .media .media-body span {
  display: block;
}

.app-profile-section .media .media-body .user-name {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}

.app-profile-section .media .media-body .user-role {
  font-size: 12px;
  font-weight: 500;
  color: #777777;
  text-transform: uppercase;
}

.app-profile-section .media .media-body .user-role-small {
  font-size: 12px;
  font-weight: 500;
  color: #777777;
  text-transform: capitalize;
}

.capitize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.app-profile-section .dropdown-menu {
  padding: 30px 30px;
  min-width: 20em;
  box-shadow: 0 1px 5px 0 rgba(191, 196, 201, 0.84);
  border: none;
  top: 80%;
  right: 10%;
  position: absolute !important;
}

.app-profile-section .navbar-nav .dropdown .nav-link {
  text-transform: capitalize;
}

.app-profile-section .navbar-nav .dropdown:hover .nav-link:hover,
.app-profile-section .navbar-nav .dropdown:hover .nav-link,
.app-profile-section .navbar-nav .nav-link.active {
  border-bottom: 4px solid transparent !important;
  text-transform: capitalize;
}

.app-profile-section .dropdown-menu .dropdown-header {
  padding: 0.5rem 0px;
}

.app-profile-section .dropdown-menu .dropdown-header .avatar img {
  width: 50px;
  height: 50px;
}

.app-profile-section .dropdown-menu .dropdown-header .user-name {
  font-size: 15px;
  color: #333333;
  font-weight: 600;
}

.app-profile-section .dropdown-menu .dropdown-header .user-email {
  font-size: 14px;
  color: #777777;
}

.app-profile-section .dropdown-menu .dropdown-item {
  font-size: 14px !important;
  padding: 15px 10px !important;
  font-weight: 600;
  color: #333333 !important;
  cursor: pointer;
  padding: 17px 0px !important;
  /* border-bottom: 1px solid #e0e0e0 !important; */
  /* border-bottom: 0px !important; */
  text-transform: capitalize !important;
}

.app-profile-section .dropdown-menu .dropdown-item:last-child {
  border-bottom: none !important;
  padding-bottom: 20px !important;
}

.app-profile-section .dropdown-menu .dropdown-item:focus {
  background-color: transparent !important;
}

.app-profile-section .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
  color: #e26640 !important;
  text-decoration: none;
}

.app-profile-section .navbar-nav .nav-item {
  border: none !important;
}

.app-profile-section .navbar-nav .nav-item.dropdown>.nav-link.active::after {
  display: none;
}

.app-profile-section .nav-item.dropdown::after {
  display: none !important;
}

@media (min-width: 320px) and (max-width: 767px) {
  .app-profile-section {
    position: absolute;
    right: 50px;
    top: 5px;
  }
}

/* Header Profile Section CSS */

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}

.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files {
  position: relative;
}

.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}

.color input {
  background-color: #f1f1f1;
}

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

/* Fade effect for ordered list CSS */
.card-body ol {
  position: relative;
}

.card-body ol:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
  width: 100%;
  height: 4em;
}

/* Fade effect for ordered list CSS */

/* Global unordered list CSS */
.unordered-list ul {
  list-style: none;
  padding-left: 20px;
}

.unordered-list ul li::before {
  content: "\2022";
  color: #25429c;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.unordered-list ul li .list-imp {
  font-weight: 600;
  position: relative;
}

.unordered-list ul li .list-imp::before {
  position: absolute;
  content: "";
  background-image: url(/static/media/underline.b8edc2a1.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 130px;
  height: 15px;
  top: 25px;
  left: 0%;
}

/* Global unordered list CSS */

/* Global ordered list CSS */
.ordered-list ol {
  list-style: none;
  counter-reset: item;
  margin-bottom: 0px;
}

.ordered-list li {
  counter-increment: item;
  margin-bottom: 20px;
}

.ordered-list li:before {
  margin-right: 10px;
  content: counter(item);
  background: #f8d874;
  border-radius: 100%;
  color: #25429c;
  width: 1.7em !important;
  text-align: center;
  display: inline-block;
  margin-left: -35px !important;
  font-weight: 700;
}

/* Global ordered list CSS */

.navbar-toggler .icon-bar {
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: #ddd;
  margin-top: 6px;
}

/* App Carousel CSS */
#app-carousel {
  min-height: 450px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(244, 246, 252, 0.53) 100%);
  border-bottom: 1px solid #e8e8e8;
}

/* #app-carousel svg{
  min-height: 400px;
} */
#app-carousel .carousel-caption {
  right: 3%;
}

#app-carousel .carousel-caption .caption-tagline {
  font-size: 25px;
  color: #777777;
  font-family: "Roboto Slab", serif;
}

#app-carousel .carousel-caption h1 {
  font-size: 50px;
  color: #25429c;
  font-family: "Roboto Slab", serif;
  font-weight: 600;
}

#app-carousel .carousel-caption p {
  color: #333333;
  font-size: 18px;
  line-height: 30px;
}

/* App Carousel CSS */

/* APP Header CSS */
.app-header.header-sec .navbar-nav .nav-item {
  padding: 0px 20px;
}

.app-header.header-sec .navbar-nav .nav-item a {
  font-size: 13px;
  padding: 36px 0px;
}

.header-sec .app-profile-section .navbar-nav .dropdown .nav-link {
  padding: 15px 0px;
}

.header-sec .app-profile-section ul li {
  padding: 0px 30px 0px 40px !important;
}

.app-header.header-sec {
  min-height: 95px;
}

.app-header.header-sec nav {
  border-bottom: 1px solid #efeeee;
  padding: 0rem 1rem;
}

.app-header {
  background-color: #ffffff;
  min-height: 120px;
}

.app-header .navbar-collapse .btn-group {
  margin-left: 30px;
}

.app-header .fixed-top {
  background-color: #ffffff;
  border-bottom: 1px solid #efeeee;
}

.app-header .navbar-nav .nav-item {
  padding: 15px 30px;
}

.app-header .navbar-nav>.nav-item a {
  font-weight: 600;
  color: #333333;
  cursor: pointer !important;
  padding: 7px 0px;
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #333333 !important;
}

.app-header .navbar-nav .nav-item .nav-link:active {
  border-bottom: 4px solid #e26640;
}

.app-header .navbar-nav .nav-item .nav-link:hover,
.app-header .navbar-nav .nav-item .nav-link:focus {
  /* border-bottom: 4px solid #e26640; */
  color: #777777;
}

.app-header .navbar-brand img {
  height: 110px;
}

.app-header .navbar-brand span {
  background-image: url(/static/media/SeedFundSchemeLogo.a7455876.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 120px;
  height: 95px;
  display: block;
  background-position: 0px -10px;
}

.navbar-brand-dashboard span {
  background-image: url(/static/media/SeedFundSchemeLogo.a7455876.svg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 66px;
  width: 85px;
  display: block;
  background-position: 0px -10px;
}

/* APP Header CSS */

/* APP Footer CSS */
.app-footer.footer-sec {
  background-color: #fdfbfb;
  padding: 25px 25px;
  /* position: fixed;
  bottom: 0;
  width: 100%; */
}

.app-footer.footer-sec {
  font-size: 14px;
  font-family: "Open Sans";
}

.app-footer {
  background-color: #364250;
  padding: 30px 0px;
}

.app-footer .footer-socialicons ul li {
  display: inline-block;
}

.app-footer .footer-socialicons ul li a {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-decoration: none;
  padding: 0px 20px;
  display: inline-block;
}

.app-footer .footer-socialicons .s-tw {
  background: url(/static/media/icon-twitter.ab732e23.svg) no-repeat left center;
}

.app-footer .footer-socialicons .s-fb {
  background: url(/static/media/icon-facebook.9467a84b.svg) no-repeat left center;
}

.app-footer .footer-socialicons .s-yt {
  background: url(/static/media/icon-youtube.ac85be1a.svg) no-repeat left center;
}

.footer-subscribe .form-group .form-control {
  box-sizing: border-box;
  border: 1px solid #555;
  /* opacity: 0.14; */
  /* background-color: rgba(255, 255, 255, 0.2); */
  background-color: #30373f;
  border-radius: 2px;
  color: #888;
  width: 275px;
}

.footer-subscribe .form-group .form-control::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9aa9c0;
  opacity: 1;
  /* Firefox */
}

.footer-subscribe .form-group .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9aa9c0;
  opacity: 1;
  /* Firefox */
}

/* .footer-subscribe .form-group .form-control::-webkit-input-placeholder{
  color: #9aa9c0;
} */
.footer-subscribe .btn-link {
  color: #e26640;
  font-size: 14px;
  text-transform: uppercase;
}

.footer-subscribe .btn-link:hover {
  color: #e26640;
}

.app-footer .footer-links ul {
  padding: 0;
  list-style: none;
}

.app-footer .footer-links .footer-heading {
  font-size: 16px;
  color: #dfe8f5;
  font-weight: 600;
}

.app-footer .footer-links ul li {
  padding: 7px 0px;
}

.app-footer .footer-links ul li a {
  color: #dfe8f5;
}

.app-footer .footer-links ul li a:hover {
  color: #e26640;
  text-decoration: none;
  cursor: pointer;
}

.app-footer .footer-top {
  padding: 40px 0px 50px;
}

.app-footer .footer-bottom {
  color: #a9aeb5;
}

.app-footer .footer-bottom ul {
  padding: 0px;
  margin: 0px;
  float: right;
}

.app-footer .footer-bottom ul li {
  list-style-type: none;
  display: inline-block;
  padding: 0px 12px;
}

.app-footer .footer-bottom ul li a {
  color: #a9aeb5;
}

.app-footer .footer-socialicons ul li a:hover {
  /* filter: #E26640; */
  -webkit-filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
          filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);
}

/* APP Footer CSS */

/* Home Section Global CSS */
/* .app-home .btn-sisfs{
  padding: 12px 3rem !important;
  border-radius: 30px !important;
} */
.section-container {
  padding: 40px 0px 40px;
}

.section-container .section-heading {
  padding: 20px 0px;
}

.section-container .section-heading h2 {
  font-family: "Roboto Slab", serif;
  font-size: 28px;
  color: #2b408b;
  text-align: center;
  text-transform: capitalize;
}

.section-container .sub-heading {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  color: #e26640;
  text-transform: capitalize;
}

.section-container .section-content {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.section-container .section-action {
  text-align: center;
}

/* Home Section Global CSS */

.section-container .section-initiative {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 60px 0px;
}

.section-container .section-initiative::before {
  /* content: '';
  background-image: url(./images/Blue-Step.svg);
  position: absolute;
  width: 100%;
  background-size: 30% 100%;
  height: 100%;
  width: 100%;
  background-position: left;
  background-repeat: no-repeat;  
  left: -10%;
  top: -27%; */

  position: absolute;
  content: "";
  background-image: url(/static/media/bluebracket1.8be95f0f.svg);
  background-repeat: no-repeat;
  background-position: -40px 0px;
  width: 400px;
  height: 400px;
  top: 10px;
  left: -15%;
}

.section-container .section-initiative::after {
  /* content: '';
  background-image: url(./images/Blue-Step.svg);
  position: absolute;
  width: 100%;
  background-size: 150% 40%;
  height: 100%;
  width: 100%;
  background-position: inherit;
  background-repeat: no-repeat;
  right: -10%;
  top: 60%; */

  position: absolute;
  content: "";
  background-image: url(/static/media/bluebracket2.0f4e15f0.svg);
  background-repeat: no-repeat;
  background-position: 25px 0px;
  width: 400px;
  height: 420px;
  bottom: 2%;
  right: -15%;
}

.section-bg-multiple {
  background: linear-gradient(to top, #fafbfc 58%, #ffffff 58%);
}

.section-bg-grey {
  background-color: #fafbfc;
}

.section-bg-yellow {
  background-color: #f8d874;
  min-height: 325px;
}

.section-home {
  min-height: 325px;
}

.section-eligibility .card {
  /* box-shadow: 0px 0px 2px 0px #00000033; */
  /* border: 1px solid #DBDBDB; */
  border-radius: 0px;
  border: none;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
}

.section-eligibility .card-header {
  background-color: #ffffff;
}

.section-eligibility .card-header .card-img-top {
  height: 100px;
}

.section-eligibility .card-header .card-heading {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  color: #2b408b;
  text-align: center;
  font-weight: 500;
  margin: 15px 0px 5px;
}

.section-bg-yellow .section-heading {
  padding: 15px 0px;
}

.section-bg-yellow .section-content {
  text-align: center;
  font-size: 18px;
  color: #25429c;
  width: 45%;
  margin: 0 auto;
}

.section-bg-yellow .section-heading h2 {
  font-weight: 600;
  line-height: 42px;
}

/* Features Section CSS */
.section-container .section-features {
  position: relative;
}

/* .section-container .section-features .section-image::after{ 
  position: absolute;
  content: "";
  background-image: url(./images/yellowbracket1.svg);
  background-repeat: no-repeat;
  background-position: 10px 0px;
  width: 600px;
  height: 425px;
  bottom: -1%;
  right: -32%;
} */
/* .section-container .section-features::before{ 
  position: absolute;
  content: "";
  background-image: url(./images/yellowbracket1.svg);
  background-repeat: no-repeat;
  background-position: 15px 0px;
  width: 600px;
  height: 425px;
  bottom: 12%;
  right: 37%;
} */
/* Latest used features section brackets */
/* .section-container .section-features .features-block::before{ 
  position: absolute;
  content: "";
  background-image: url(./images/yellowbracket1.svg);
  background-repeat: no-repeat;
  background-position: 15px 7px;
  width: 600px;
  height: 425px;
  top: 0%;
  right: 70%;
}
.section-container .section-features::after{ 
  position: absolute;
  content: "";
  background-image: url(./images/yellowbracket2.svg);
  background-repeat: no-repeat;  
  background-position: 0px -1px;
  width: 160px;
  height: 150px;
  bottom: -8%;
  right: 3%;
} */
/* Latest used features section brackets */
.section-features .features-block {
  border: 1px solid #dbdbdb;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(142, 158, 198, 0.36);
  padding: 50px 35px 50px;
}

.section-features .features-block li {
  font-size: 18px;
  line-height: 28px;
  color: #25429c;
  font-weight: 600;
  margin-bottom: 30px;
  position: relative;
}

.section-features .features-block li:last-child {
  margin-bottom: 0px;
}

.features-block .ordered-list li:before {
  /* content: "✓"; */
  content: "";
  background-image: url(/static/media/tick.9684def9.svg);
  background-repeat: no-repeat;
  background-position: 2px 1px;
  width: 25px !important;
  height: 25px;
  line-height: 24px;
  margin-left: -38px !important;
  position: absolute;
  top: 5px;
}

/* Features Section CSS */

/* Operation Section CSS */
.section-operation {
  text-align: center;
  margin-bottom: 20px;
}

.operation-workflow h4 {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  min-height: 40px;
}

.operation-workflow .circle:first-child h4 {
  margin: 10px 7%;
}

.operation-workflow .circle h4 {
  margin: 10px 15%;
}

.circle .bd-placeholder-content p {
  margin: 0 12%;
}

.section-operation .bd-placeholder-img span {
  color: #1e2dbe;
  font-size: 20px;
  font-weight: 600;
  top: 40%;
  position: relative;
}

.section-bg-grey .bd-placeholder-img {
  background-color: #f8d874;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  margin-bottom: 20px;
}

/* .bd-placeholder-img:after {
  content: "";
  width: 155px;
  height: 70px;
  z-index: 9;
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  position: absolute;
  right: -150px;
} */
.bd-placeholder-img:after {
  position: absolute;
  content: "";

  width: 130px;
  height: 30px;
}

.circle {
  width: 23%;
  float: left;
  margin: 0px 5px;
}

.circle:nth-child(1) .bd-placeholder-img:after,
.circle:nth-child(3) .bd-placeholder-img:after {
  /* border-top: dotted 2px #25429C;
  top: -5px; */
  background-image: url(/static/media/arrow1.7c14be54.svg);
  background-repeat: no-repeat;
  background-position: 10px 0px;
  top: 0%;
  font-size: 3px;
  left: 85%;
}

.circle:nth-child(2) .bd-placeholder-img:after,
.circle:nth-child(4) .bd-placeholder-img:after {
  /* border-bottom: dotted 2px #25429C;
  bottom: -5px; */
  background-image: url(/static/media/arrow1.7c14be54.svg);
  background-repeat: no-repeat;
  background-position: 10px 0px;
  /* bottom: 15%;
  font-size: 3px;
  left: 100%; */
  bottom: 0%;
  font-size: 3px;
  left: 85%;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.circle:last-child .bd-placeholder-img:after {
  content: none;
}

/* Operation Section CSS */

/* Testomonial CSS */
figure.testimonial img {
  max-width: 100%;
  vertical-align: middle;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  margin: 40px 0 0 10px;
}

figure.testimonial blockquote {
  font-family: "Roboto Slab", serif;
  background-color: #fff;
  color: #333333;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  margin: 0;
  padding: 25px 50px 30px 70px;
  position: relative;
  border: 4px solid #f8d874;
}

figure.testimonial blockquote:before {
  /* content: "\201C"; */
  /* color: #E26640;
	font-size: 50px;
	font-style: normal; */
  /* content: "";
  font-style: normal; */
  position: absolute;
  content: "";
  background-image: url(/static/media/quote-icon.deb44ef8.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 40px;
  height: 35px;
}

figure.testimonial blockquote:before {
  top: 15px;
  left: 20px;
}

figure.testimonial .arrow {
  top: 100%;
  width: 0;
  height: 0;
  border-left: 0 solid #f8d874;
  border-right: 25px solid transparent;
  border-top: 25px solid #f8d874;
  margin: 0;
  position: absolute;
}

figure.testimonial .user {
  position: absolute;
  bottom: 45px;
  padding: 0 10px 0 120px;
  margin: 0;
  -webkit-transform: translateY(35%);
  transform: translateY(35%);
}

figure.testimonial .user h5 {
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}

/* Testomonial CSS */

/* Offcanvas CSS for login */
/* html,
body {
  overflow-x: hidden; 
  /* Prevent scroll on narrow devices 
} */

/* @media (max-width: 991.98px) { */
.offcanvas-collapse {
  position: fixed;
  /*top: 56px;*/
  /* Height of navbar */
  bottom: 0;
  left: 100%;
  width: 100%;
  top: 0px;
  z-index: 1050;
  padding-right: 1rem;
  padding-left: 0px;
  overflow-y: auto;
  visibility: hidden;
  background-color: #ffffff;
  border-left: 1px solid #ddd;
  transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.offcanvas-collapse.open {
  visibility: visible;
  -webkit-transform: translateX(-35%);
  transform: translateX(-35%);
}

/* } */

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.bg-purple {
  background-color: #6f42c1;
}

.lh-100 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25;
}

.lh-150 {
  line-height: 1.5;
}

/* .offcanvas-collapse.open .aside-dropdown__inner {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
} */
/* .aside-dropdown__inner {
  position: relative;
  width: 320px;
  min-height: 100%;
  -webkit-transform: translateX(-320px);
  -ms-transform: translateX(-320px);
  transform: translateX(-320px);
  padding: 110px 0 50px 0;
  background-color: #f9f7f6;
  -webkit-box-shadow: 0 1px 10px rgba(0,0,0,.3);
  box-shadow: 0 1px 10px rgba(0,0,0,.3);
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
} */
.offcanvas-collapse.open {
  opacity: 1;
  visibility: visible;
}

/* Offcanvas CSS for login */

/* Login Screen CSS */
#overlay {
  display: none;
}

#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  cursor: pointer;
}

.aside-dropdown__inner {
  height: 100%;
}

.login {
  height: 100%;
  width: 100%;
  /* width: 35%; */
  overflow-y: auto;
}

.login-wrapper {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
  height: 100%;
  position: relative;
}

.login-wrapper .login-heading,
.confirm-page-wrapper .login-heading {
  font-size: 30px;
  color: #2b408b;
  font-family: "Roboto Slab", serif;
}

.confirm-page-wrapper {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  flex-direction: row;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.confirm-page-wrapper .form-signin img {
  width: 120px;
}

.login-wrapper .close {
  position: absolute;
  top: 0px;
  right: 10%;
  font-size: 42px;
  font-weight: 500;
}

.login-wrapper .form-control {
  padding: 20px 0.75rem;
  color: #777777;
}

.login-wrapper .btn-sisfs {
  padding: 10px 20px !important;
  font-size: 16px !important;
  font-weight: 600;
}

.form-signin {
  width: 100%;
  max-width: 370px;
  padding: 0px 15px;
  margin: auto;
}

/* Login Screen CSS */

/* Inside Page Incubator CSS */
.form-wrapper {
  padding: 10px 0px 10px;
}

.form-wrapper .form-content {
  width: 88%;
  margin: 0 auto;
}

.form-wrapper .form-group {
  margin-bottom: 25px;
}

.form-wrapper .form-group .form-control {
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 11px 0.75rem;
  height: auto;
  font-size: 15px;
  line-height: 22px;
}

.form-wrapper .form-group .custom-control {
  z-index: inherit;
}

.react-datepicker-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
}

.react-datepicker-wrapper .form-control {
  cursor: pointer;
}

.react-datepicker-wrapper:after {
  /* font-family: 'FontAwesome';
    content: "\f073"; */
  content: "";
  font-size: 20px;
  position: absolute;
  right: 10px;
  color: #25429c;
  top: 7px;
  cursor: pointer;
  background-image: url(/static/media/calendar.7d0fa8c0.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 25px;
  height: 25px;
}

.form-wrapper .form-group .custom-radio,
.form-wrapper .form-group .custom-checkbox {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 26px;
  color: #333333;
}

.form-wrapper .form-group .custom-radio:last-child,
.form-wrapper .form-group .custom-checkbox:last-child {
  margin-bottom: 0px;
}

.form-wrapper .form-group .custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.stepper-container .agree-confirm-box .custom-control-label {
  font-size: 15px;
  color: #333333;
}

.form-wrapper .form-group small {
  color: #777777;
  font-size: 14px;
  display: block;
  padding: 10px 0px;
}

.form-wrapper .action-section {
  margin-top: 40px;
}

.form-wrapper .form-group .col-form-label {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: #333333;
}

.app-inside .section-bg-yellow {
  min-height: auto;
}

.app-inside-content {
  padding: 0px 0px;
}

.app-inside-content hr {
  margin: 30px 0px;
}

.app-inside .app-banner-inside .sub-heading {
  font-size: 18px;
  line-height: 30px;
  color: #273444;
  margin-top: 15px;
}

.app-banner-inside {
  min-height: 230px;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.app-banner-inside .main-heading,
.app-banner-inside .sub-heading {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.stepper-container .stepper-content {
  padding: 40px 0px;
}

.stepper-container .agree-confirm-box {
  background-color: #fafbfc;
  padding: 20px 20px;
}

.stepper-container .agree-confirm-box .form-check-label {
  font-size: 18px;
}

.stepper-container .stepper-footer-section {
  padding: 30px 0px 15px;
  border-top: 2px solid #e0e0e0;
  margin-top: 30px;
}

.stepper-container .stepper-footer-section .btn-group .btn-sisfs {
  margin-left: 15px;
}

.stepper-footer-section .stepper-page-count {
  color: #333333;
  font-weight: 600;
}

.stepper-container .form-group .col-form-label {
  font-size: 15px;
  line-height: 22px;
  font-weight: 600;
  color: #333333;
}

.stepper-container .form-group .form-check .form-check-input {
  margin-top: 0.5rem;
}

.stepper-container .form-group ul {
  padding-left: 20px;
  font-size: 14px;
  line-height: 25px;
  color: #777777;
  margin: 10px 0px;
}

.stepper-container .form-group ul li {
  padding: 2px 0px;
}

.stepper-container .form-group small {
  color: #777777;
  font-size: 14px;
  display: block;
  padding: 3px 0px 0px;
}

.stepper-container .form-group .col-label {
  font-size: 15px;
  line-height: 22px;
  color: #333333;
  padding: 10px 0px;
  display: block;
  font-weight: 600;
}

.stepper-container .form-group .col-form-label.disabled {
  color: #e9ecef;
  opacity: 1;
}

.stepper-container .form-group .form-row {
  margin-bottom: 0;
}

.stepper-container .form-group .form-check {
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 26px;
  color: #333333;
}

.form-group .input-group .input-group-addon {
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #555;
  text-align: center;
  border: 1px solid #ccc;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: 0px;
}

.stepper-container .form-group .upload-pitch .link-orange-text {
  padding: 10px 0px;
}

.app-banner-inside .nav-tabs {
  display: inline-flex;
  align-items: center;
  border-bottom: none;
}

.app-banner-inside .nav-tabs .nav-item.show .nav-link,
.app-banner-inside .nav-tabs .nav-link.active {
  color: #333333 !important;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px;
  border-bottom: 4px solid #e26640 !important;
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem 0rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.app-banner-inside .nav-tabs .nav-item .nav-link {
  color: #555555;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 500;
  padding: 1rem 0px;
  margin: 0px 25px;
}

/* Inside Page Incubator CSS */

/* span {
  cursor: pointer;
} */
/* .number{
  margin:100px;
} */
.minus,
.plus {
  width: 28px;
  height: 28px;
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 4px 4px 4px 4px;
  /* border: 1px solid #ddd; */
  display: inline-block;
  vertical-align: middle;
  /* text-align: center;
  color: #25429C;
  font-size: 20px; */
}

.plus .fa-plus,
.minus .fa-minus {
  text-align: center;
  color: #25429c;
  padding: 3px 5px;
}

.number input {
  /* height:34px;
  width: 100px;
  text-align: center;
  font-size: 26px;
  border:1px solid #ddd;
  border-radius:4px;
  display: inline-block;
  vertical-align: middle; */
  text-align: center;
  margin: 0 15px;
  box-sizing: border-box;
  height: 38px;
  width: 55px;
  border: 1px solid #cecece;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: inset 0 0 6px 0 rgba(0, 0, 0, 0.27);
}

fieldset[disabled] .number input {
  background-color: #e9ecef;
  opacity: 1;
}

/* Stepper CSS */
.bs-stepper-content {
  padding: 10px 20px 10px !important;
}

.bs-stepper-header {
  padding: 20px 0px;
}

.bs-stepper-header .step {
  width: 20%;
  text-align: center;
  min-height: 110px;
}

.bs-stepper .bs-stepper-header .step-trigger {
  white-space: inherit;
  padding: 10px 0px;
  min-height: 52px;
  min-width: 100px;
}

.bs-stepper .bs-stepper-header .step-trigger:hover {
  background-color: transparent;
}

.bs-stepper .bs-stepper-header .bs-stepper-label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #777777;
  font-family: "Roboto Slab", serif;
  width: 90%;
  margin: 0 auto;
  min-height: 40px;
  font-weight: normal;
}

.bs-stepper .bs-stepper-header .badge-red {
  /* height: 23.45px;
  width: 105px; */
  border-radius: 13.5px;
  background-color: #f7d5d5;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  display: block;
  text-align: center;
  margin: 0 25px;
  color: #d00c0c;
  padding: 4px 10px;
  width: auto;
  height: auto;
}

.bs-stepper .bs-stepper-header .step.active .bs-stepper-label {
  font-weight: 600;
  color: #333333;
}

.bs-stepper .line,
.bs-stepper-line {
  position: relative;
  bottom: 27px;
  left: 50%;
}

#reapply_main {
  margin-top: 0;
}

.bs-stepper-circle {
  z-index: 20;
}

.bs-stepper-circle-error {
  height: 13px !important;
  width: 13px !important;
  border: 2px solid #d00c0c;
  background-color: #ffffff !important;
  padding: 4px 0px !important;
  border-radius: 50%;
  z-index: 99;
}

.active .bs-stepper-circle-error {
  background-color: #d00c0c !important;
  width: 1.5em !important;
  height: 1.5em !important;
}

.bs-stepper-circle {
  height: 13px !important;
  width: 13px !important;
  border: 2px solid #f8d874;
  background-color: #ffffff !important;
  padding: 4px 0px !important;
}

.bs-stepper-circle-error {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: 0.5em 0;
  margin: 0.25rem;
  line-height: 1em;
  color: #fff;
  background-color: #6c757d;
  border-radius: 1em;
}

.step.active .bs-stepper-circle {
  background-color: #f8d874 !important;
  width: 20px !important;
  height: 20px !important;
  margin-top: 1px;
}

.bs-stepper .btn-group-toggle .btn {
  border: 1px solid #96a2c7;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  font-weight: 700;
}

.toggle-btn-group .btn-group-toggle .btn {
  border: 1px solid #96a2c7;
  padding: 8px 20px;
  font-size: 12px;
  line-height: 18px;
  color: #777777;
  font-weight: 700;
}

.toggle-btn-group .btn-group-toggle .btn.active {
  /* background-color: #e7e7e7; */
  /* color: #3e4d7a; */
  color: #ffffff;
  background-color: #25429c !important;
  border: 1px solid #25429c !important;
}

.toggle-btn-group .btn-group-toggle.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.toggle-btn-group .btn-group-toggle.btn-group>.btn:not(:first-child) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.bs-stepper .btn-group-toggle .btn.active {
  /* background-color: #e7e7e7; */
  /* color: #3e4d7a; */
  color: #ffffff;
  background-color: #25429c !important;
  border: 1px solid #25429c !important;
}

.bs-stepper .btn-group-toggle.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bs-stepper .btn-group-toggle.btn-group>.btn:not(:first-child) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#incubatorstepper.bs-stepper .bs-stepper-header .step-trigger {
  min-height: 94px;
  min-width: 100px;
  width: 100%;
}

#incubatorstepper.bs-stepper .bs-stepper-line {
  bottom: 59px;
}

#incubatorstepper .bs-stepper-header .step {
  min-height: 125px;
}

#incubatorstepper .bs-stepper-header {
  height: 130px;
}

#startupstepper .stepper-form-5 .col-label {
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  padding: 2px 0px;
  display: block;
  font-weight: 600;
}

#startupstepper .red-text {
  color: #d00c0c;
}

#startupstepper .bs-stepper-header .step-trigger {
  min-height: 97px;
  min-width: 100px;
  width: 100%;
}

#startupstepper.bs-stepper .bs-stepper-line {
  bottom: 62px;
}

#startupstepper .bs-stepper-header {
  height: 130px;
}

#startupstepper .bs-stepper-header .step {
  min-height: 125px;
}

#startupstepper .bs-stepper-header .badge-red {
  margin: 0 8px;
}

/* Stepper CSS */

.link-orange-text .fa-plus::before {
  font-weight: 500;
  font-size: 10px;
}

#drop-area,
._KhXkb {
  width: auto !important;
  margin: 10px auto !important;
  border-radius: 5px !important;
  text-align: center !important;
}

#drop-area .fa-cloud-upload,
._KhXkb .fa-cloud-upload {
  font-size: 22px;
  color: #25429c;
}

#drop-area ul li,
._KhXkb ul li {
  text-align: left;
}

#drop-area ul li span:last-child::before,
._KhXkb ul li span:last-child::before {
  font-family: "FontAwesome";
  content: "\f00d";
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

#drop-area>div,
._KhXkb>div {
  margin-bottom: 0px;
}

#drop-area input[type="file"]+label,
._KhXkb input[type="file"]+label {
  border-radius: 20px !important;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 14px !important;
  padding: 0.375rem 2rem !important;
  background-color: #ffffff !important;
  border: 1px solid #25429c !important;
  color: #25429c !important;
}

#drop-area,
._KhXkb ul li {
  position: relative;
}

._KhXkb ul li ._2I1sI {
  position: absolute;
  right: -15px;
  top: 0px;
}

.stepper-container .form-group ul._1E4wG li {
  display: flex;
  align-items: center;
}

ul._1E4wG li ._3XNKu {
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  display: inline-flex;
}

#FormSubmissionSuccess .fa-check-circle-o {
  font-size: 48px;
  font-weight: 500;
  color: #80bd59;
}

#FormSubmissionSuccess .fa-times-circle-o {
  font-size: 48px;
  font-weight: 500;
  color: #d00c0c;
}

.form-group.required .col-form-label:after {
  content: "*";
  color: #d00c0c;
}

/* Apply Now Incubator */
.app-apply-incubator .app-banner-inside {
  min-height: 400px !important;
  padding-top: 80px !important;
}

.app-apply-incubator .app-banner-inside .caption-tagline {
  font-size: 25px;
  color: #777777;
  font-family: "Roboto Slab", serif;
  width: 50%;
  margin: 0 auto;
  display: block;
}

.app-apply-incubator .app-banner-inside .main-heading {
  font-size: 50px;
  color: #25429c;
  font-family: "Roboto Slab", serif;
  font-weight: 600;
  width: 40%;
}

.app-apply-incubator .app-banner-inside .sub-heading {
  color: #333333;
  font-size: 18px;
  line-height: 30px;
  width: 40%;
}

/* .eligibility-wrapper .section-image .height-250 img{
  height: 225px;
} */
.eligibility-wrapper .section-image img {
  height: 200px;
  margin-bottom: 1%;
}

.eligibility-wrapper .content-section .content-heading {
  font-family: "Roboto Slab", serif;
  font-size: 24px;
  color: #2b408b;
  text-align: center;
  font-weight: 500;
  margin: 15px 0px 5px;
}

.eligibility-wrapper .content-section .content-body {
  font-size: 15px;
  line-height: 25px;
  color: #333333;
  text-align: center;
  font-weight: 500;
  width: 75%;
  margin: 0 auto;
}

.status-list-wrapper .status-list {
  padding: 20px 20px;
  background-color: #f8f7f7;
  width: 100%;
  margin-top: 20px;
}

.status-list-wrapper .status-list:first-child {
  margin-top: 0px;
}

.status-list-wrapper .status-list .status {
  width: 40%;
}

.status-list-wrapper .status-list h4 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}

/* Review CSS EAC */
.review-overview-wrapper {
  padding: 15px 15px;
  display: flex;
  margin: 0 auto;
  width: 80%;
  display: flex;
  align-items: center;
  align-items: normal;
  margin-bottom: 25px;
}

.review-overview-wrapper .review-question {
  background-color: #f8f7f7;
  padding: 15px 20px;
  width: 70%;
}

.review-overview-wrapper .review-question h5 {
  font-size: 15px;
  color: #333333;
  font-weight: 600;
}

.review-overview-wrapper .review-question h5 span {
  font-size: 14px;
  color: #555555;
}

.review-overview-wrapper .review-score {
  background-color: #eceff5;
  padding: 15px 30px;
  width: 30%;
  align-items: center;
  display: grid;
  margin: 0 auto;
}

.review-overview-wrapper .review-score .score-value {
  font-size: 22px;
  color: #25429c;
  font-weight: 600;
}

/* Review CSS EAC */

button.close {
  font-size: 42px;
  font-weight: 500;
}

#ApplyNowModal .modal-header {
  border-bottom: none;
  padding: 15px 30px;
}

#ApplyNowModal .modal-dialog {
  max-width: 780px !important;
}

.card-apply.notshown {
  opacity: 0.5;
}

.notshown .btn {
  cursor: not-allowed !important;
}

.card-apply img {
  height: 110px;
}

.card-apply {
  margin: 0 auto;
  width: 85%;
}

.card-apply .card-apply-heading {
  font-size: 20px;
  color: #25429c;
  font-family: "Roboto Slab", serif;
  margin-bottom: 15px;
}

.card-apply .card-apply-content {
  font-size: 15px;
  color: #333333;
}

.call-applicaton h6 {
  font-weight: 500;
  color: #25429c;
}

.train-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.train-text-wrapper {
  display: flex;
}

.train-text {
  white-space: nowrap;
  margin-right: 40px;
  /* color: gray; */
  font-size: 16px;
  font-weight: 600;
  -webkit-animation: train-animation 20s linear infinite;
          animation: train-animation 20s linear infinite;
}

@-webkit-keyframes train-animation {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes train-animation {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}



.carousel-item.active {
  display: flex !important;
  height: 100%;
  min-height: 450px;
  align-items: center;
}

#app-carousel .carousel-caption {
  right: 3%;
  position: initial;
}

.btn-transparent-orange.btn-sisfs {
  background-color: #ffffff !important;
  border: 1px solid #e26640 !important;
  color: #e26640 !important;
  padding: 12px 25px !important;
  font-size: 12px !important;
  margin: 5px 15px 0px 15px !important;
}

.btn-transparent-orange-startup.btn-sisfs {
  background-color: #ffffff !important;
  border: 1px solid #e26640 !important;
  color: #e26640 !important;
  padding: 10px 33px !important;
  font-size: 12px !important;
  margin: 5px 15px 0px 15px !important;
}

.form-group.required .disabled.col-form-label:after {
  color: #d00c0c;
  opacity: 0.3;
}

.form-group .error {
  color: #d00c0c;
  padding: 5px 0px;
}

input.error,
select.error,
textarea.error {
  border: 1px solid #d00c0c !important;
  color: #333333 !important;
}

.glossary-page li {
  width: 85%;
}

.glossary-page.about-list>li>a {
  line-height: 20px;
  padding: 12px 0px;
  display: block;
}

.glossary-page.about-list>li>a.active {
  line-height: 20px;
}

.glossary-page.about-list>li>a.active::after {
  content: "--";
  font-weight: 1000;
  color: #e26640;
  width: 8px;
  min-width: 8px;
  border-bottom: solid 0px transparent;
  position: relative;
  top: 0%;
  z-index: 1;
  right: -10px;
  height: 12px;
}

.startup-heading-review .text-right-wrap {
  display: flex;
  align-items: center;
}

.startup-heading-review .text-right-wrap .searchBar {
  width: auto;
  min-width: 240px;
}

/* Media Queries for Responsive screens */
/* / Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .form-wrapper .form-group {
    margin-bottom: 15px;
  }

  .section-container .section-initiative::before,
  .section-container .section-initiative::after {
    content: "";
    background-image: none;
    width: 0px;
    height: 0px;
  }

  .operation-workflow h4 {
    min-height: inherit;
  }

  .operation-workflow .circle {
    width: 100%;
    float: none;
    margin-bottom: 30px;
  }

  .bd-placeholder-img:after {
    background-image: none !important;
  }

  .master-footer .form-inline {
    display: inline-block;
  }

  .footer-subscribe .form-group .form-control {
    width: auto;
  }

  .section-container .img-fluid {
    margin: 0 auto;
    display: block;
  }

  .section-bg-yellow .section-content {
    width: 100%;
  }

  .master-footer .footer-links {
    margin-bottom: 30px;
  }

  /* inside page media CSS */

  .about-ordered-list li .link-orange-text {
    word-wrap: break-word;
  }

  .about-container .img-div-row {
    display: block;
  }

  .about-container .img-div {
    /* margin-right: 3%; */
    width: 45%;
    display: inline-block;
    min-height: 230px;
    vertical-align: middle;
    text-align: center;
  }

  .about-container .card-heading {
    width: auto !important;
  }

  .about-container .card-text {
    width: auto;
  }

  .about-container .offset-4 {
    margin-left: 0px;
  }

  .about-container .objectives-nav>ul.nav-tabs {
    margin-left: 0px !important;
  }

  .about-container .fixed {
    position: static;
  }

  .sticky-dropdown-section {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 77px;
    z-index: 99;
    background-color: #fff;
    width: 100%;
    margin-bottom: 30px;
  }

  .sticky-dropdown-section .sticky-dropdown button {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 0px !important;
    text-align: left;
    padding: 12px 15px !important;
    border: 1px solid #efeeee !important;
  }

  .sticky-dropdown-section .sticky-dropdown .about-list {
    width: 100%;
    padding: 10px 25px;
    margin-top: 0px;
    border-radius: 0px;
  }

  /* .sticky-content .right-cont{
    position: relative;
    top: 122px;
  } */
  /* .sticky-content .right-cols{
    margin-top: 20%;
  } */

  .faq-container .faq-heading.main-heading {
    font-size: 32px;
  }

  /* .question-tab .para-text{
    flex: 0 0 85%;
    max-width: 85%;
  } */
  .question-tab .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
    text-align: right;
  }

  .sticky-dropdown-section .sticky-dropdown button::after {
    content: "";
    position: absolute;
    right: 17px;
    top: 17px;
    border: solid #999;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .sticky-dropdown-section .dropdown.show button::after {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg);
    top: 20px;
  }

  .app-home .container,
  .app-header .container-fluid,
  .app-footer .container,
  .about-container .container,
  .app-inside .container {
    max-width: 100%;
    padding: 0px 15px;
  }

  .app-inside-content {
    padding: 0px 0px;
  }

  .app-header {
    min-height: 75px;
  }

  .app-header .navbar-collapse {
    min-height: 100vh;
  }

  .app-header .navbar-brand span {
    width: 65px;
    height: 50px;
  }

  .navbar-nav .nav-item>.nav-link.active {
    border-bottom: 4px solid transparent;
    position: relative;
    display: inline-block;
  }

  .app-header .navbar-nav .nav-item .nav-link:active {
    border-bottom: 4px solid transparent;
  }

  .app-header .navbar-nav .nav-item {
    padding: 10px 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
  }

  .app-header .navbar-collapse .header-action-section {
    position: fixed;
    bottom: 25px;
    /* width: 100%; */
    padding: 20px 0px 10px 0px;
    left: 20px;
    right: 20px;
    margin-left: 0px;
    border-top: 1px solid #ddd;
    display: none;
  }

  .app-header .navbar-nav .nav-item:first-child::after {
    border: none;
  }

  .app-header .navbar-nav .nav-item.dropdown::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 20px;
    border: solid #999;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .app-header .navbar-nav .nav-item>.nav-link.active::after {
    content: "";
    font-weight: 1000;
    color: #e26640;
    width: 8px;
    min-width: 8px;
    border-bottom: solid 2px #e26640;
    position: absolute;
    top: 20%;
    z-index: 1;
    right: -20px;
    height: 12px;
  }

  #app-carousel .carousel-caption h1 {
    font-size: 28px;
  }

  #app-carousel .carousel-caption p {
    color: #333333;
    font-size: 18px;
    line-height: 28px;
  }

  .section-container .section-heading h2 {
    font-family: "Roboto Slab", serif;
    font-size: 20px;
  }

  .section-container .sub-heading {
    font-family: "Roboto Slab", serif;
    font-size: 18px;
  }

  .section-container .section-content {
    font-size: 14px;
  }

  .section-container .section-initiative {
    padding: 0px 0px;
  }

  .btn-large.btn-sisfs {
    padding: 10px 3rem !important;
    font-size: 13px !important;
  }

  .about-heading .main-heading {
    font-size: 24px !important;
  }

  .main-heading {
    font-size: 20px !important;
  }

  .para-orange {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .para-text {
    font-size: 14px !important;
    line-height: 24px !important;
  }

  .about-ordered-list li {
    line-height: 30px;
    font-size: 16px !important;
    color: #333333;
  }

  .ordered-list li:before {
    width: 26px !important;
  }

  .about-ordered-list li:before {
    width: 30px !important;
  }

  .about-left-img {
    margin-top: 0px !important;
  }

  .right-cols {
    padding-bottom: 30px !important;
  }

  .question-tab .para-text.question-heading,
  .question-tab .para-text.question-heading-orange {
    padding-right: 25px;
    flex: 0 0 85%;
    max-width: 85%;
  }

  .question-tab .para-text.collapse {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .question-tab .ordered-list li:before {
    width: 26px !important;
  }

  .question-tab .expand-icon,
  .question-tab .hide-icon {
    margin-top: 15px !important;
    margin-right: -15px;
  }

  .question-tab .para-text .link-orange-text {
    word-wrap: break-word;
  }

  .about-container .tab-content {
    margin-top: 0px;
  }

  .app-inside-glossary .ordered-list li:before {
    width: 30px !important;
  }

  .navbar-toggler {
    padding: 0.25rem 0rem !important;
  }

  .navbar-toggler .icon-bar {
    background-color: #666;
  }

  #navbar-close .close {
    color: #666;
  }

  .modal-dialog.modal-dialog-slideout {
    width: 100% !important;
    margin: 0px !important;
  }

  #app-carousel .carousel-caption .caption-tagline {
    font-size: 20px;
  }

  #app-carousel .carousel-caption .btn-sisfs {
    margin-bottom: 10px;
    width: 100% !important;
    margin-left: 0 !important;
  }

  .section-container .section-initiative {
    padding: 20px 0px;
  }
}

.custom-spinner {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  flex-direction: row;
  position: fixed;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 99;
  width: 30px !important;
  height: 30px !important;
  color: #25429c;
}

#incubator-step-5 {
  position: relative;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .about-container .img-div-row {
    display: block;
  }

  .about-container .img-div {
    /* margin-right: 3%; */
    width: 45%;
    display: inline-block;
    margin-bottom: 20px;
    min-height: 225px;
    vertical-align: middle;
  }

  .bd-placeholder-img:after {
    background-image: none !important;
  }

  .about-ordered-list li .link-orange-text {
    word-wrap: break-word;
  }

  #app-carousel {
    min-height: 400px;
  }

  .carousel-item.active {
    min-height: 400px;
  }

  #app-carousel .carousel-caption h1 {
    font-size: 28px;
  }

  .app-header {
    min-height: 75px;
  }

  .app-header .navbar-collapse {
    min-height: 100vh;
  }

  .app-header .navbar-brand span {
    width: 65px;
    height: 50px;
  }

  .navbar-nav .nav-item>.nav-link.active {
    border-bottom: 4px solid transparent;
    position: relative;
    display: inline-block;
  }

  .app-header .navbar-nav .nav-item .nav-link:active {
    border-bottom: 4px solid transparent;
  }

  .app-header .navbar-nav .nav-item {
    padding: 10px 10px;
    border-bottom: 1px solid #ddd;
    position: relative;
  }

  .app-header .navbar-collapse .header-action-section {
    position: fixed;
    bottom: 25px;
    /* width: 100%; */
    padding: 20px 0px 10px 0px;
    left: 20px;
    right: 20px;
    margin-left: 0px;
    border-top: 1px solid #ddd;
  }

  .app-header .navbar-nav .nav-item:first-child::after {
    border: none;
  }

  .app-header .navbar-nav .nav-item::after {
    content: "";
    position: absolute;
    right: 10px;
    top: 20px;
    border: solid #999;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .app-header .navbar-nav .nav-item>.nav-link.active::after {
    content: "";
    font-weight: 1000;
    color: #e26640;
    width: 8px;
    min-width: 8px;
    border-bottom: solid 2px #e26640;
    position: absolute;
    top: 20%;
    z-index: 1;
    right: -20px;
    height: 12px;
  }

  .app-header .navbar-nav .nav-item.dropdown>.nav-link.active::after {
    display: none;
  }

  .navbar-toggler .icon-bar {
    background-color: #666;
  }

  #navbar-close .close {
    color: #666;
  }

  #app-carousel .carousel-caption .btn-sisfs {
    margin-bottom: 10px;
    margin-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .section-container .section-initiative::before,
  .section-container .section-initiative::after {
    content: "";
    background-image: none;
    width: 0px;
    height: 0px;
  }

  .section-container .img-fluid {
    margin: 0 auto;
    display: block;
  }

  .operation-workflow .circle {
    margin-bottom: 30px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 960px !important;
  }
}

@media (max-width: 768px) {
  .operation-workflow .circle {
    width: 100%;
    float: none;
  }
}

@media (min-width: 1600px) {

  /* .app-footer.footer-sec{
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
 .app-inside{
    padding-bottom: 70px !important;
  }*/
  .dashboardContainer {
    padding-bottom: 40px !important;
  }

  .container-min {
    min-height: 82vh !important;
  }
}

.play-span {
  cursor: pointer;
}

/*Drag drop*/

/* add css module styles here (optional) */
/* #drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 480px;
  font-family: sans-serif;
  margin: 100px auto;
  padding: 20px;
}
#drop-area.highlight {
  border-color: purple;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery embed {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
}
#gallery > div {
  display: inline-block;
  width: 50%;
  position: relative;
}
#gallery > div .close::before {
  content: "x";
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10%;
} */
/* @media (min-width: 576px){
  .modal-dialog {
      max-width: 500px !important;
      margin: 1.75rem auto;
  }
  } */
.modal-text {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}

.form-content .score-range-tab:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.score-range-tab {
  margin-top: 30px;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #d8d8d8;
}

.score-range-tab .left-box {
  width: 90%;
}

.score-tab .nav-link {
  display: block;
  padding: 0.5rem 20px;
  color: #333333;
  cursor: pointer;
}

.score-tab .nav-item:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.score-tab .nav-item:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.score-tab .nav-link.active-text {
  color: #fff;
}

.score-range-tab .weighted-score {
  border-left: 1px solid #e3e3e3;
}

.review-head {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22px;
}

.review-head+.review-head {
  margin-top: 40px;
}

.left-box {
  margin-top: 10px;
  background-color: #f8f7f7;
}

.text-count {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
}

.review-top {
  padding-bottom: 40px;
  border-bottom: 0.5px solid #d8d8d8;
}

.left-link {
  border-top: 0.5px solid #96a2c7;
  border-bottom: 0.5px solid #96a2c7;
  border-left: 0.5px solid #96a2c7;
  border-right: 0.5px solid #96a2c7;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.right-link {
  border-top: 0.5px solid #96a2c7;
  border-bottom: 0.5px solid #96a2c7;
  border-right: 0.5px solid #96a2c7;
  border-left: 0.5px solid #96a2c7;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.middle-link {
  border: 0.5px solid #96a2c7;
}

.middle-link.active {
  border: 1px solid #25429c;
  background-color: #25429c;
}

.score-tab {
  margin-top: 15px;
}

.link-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.active-text {
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.weighed-score {
  color: #555555;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.weighed-score-count {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}

.text-area-review {
  width: 100%;
  height: 123px;
  border: 1px solid #cecece;
  border-radius: 3px;
  background-color: #ffffff;
}

.container-min {
  min-height: 550px;
  padding-bottom: 40px;
}

.section-padding {
  width: 55% !important;
}

#ReviewScoreModal .modal-content .modal-body {
  padding: 10px 60px;
}

#ReviewScoreModal .score-list ol {
  padding-left: 0px;
  margin-bottom: 0px;
}

#ReviewScoreModal .score-list ol li:first-child {
  border-top: 0px;
}

#ReviewScoreModal .score-list ol li {
  padding: 15px 0px;
  border-top: 1px solid #c9c9c9;
}

#ReviewScoreModal .modal-header h5 {
  color: #25429c;
  font-size: 38px;
  font-weight: 500;
}

/* #ReviewScoreModal .score-list ol li::marker {
  color: red;
  font-weight: bold;
} */
.devider-before::before {
  content: "|";
  margin-right: 5px;
}

.tab-login {
  font-size: 24px !important;
}

.error-message {
  color: red;
  margin-top: 5px;
  margin-left: 2px;
}

.note {
  position: relative;
  width: 100%;
  height: 34px;
  border: 1px solid #e5e3b7;
  opacity: 0.54;
  background-color: #fffcd1;
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 40px;
}

/* .note:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 16px 16px 0;
  border-style: solid;
  border-color: #fff #fff #FFFF99 #FFFF99;
  background: #FFFF99;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  box-shadow: 0 1px 1px rgba(0,0,0,0.3), -1px 1px 1px rgba(0,0,0,0.2);
  /* Firefox 3.0 damage limitation 
  display: block; width: 0;
}

.note.rounded {
  -moz-border-radius: 5px 0 5px 5px;
  border-radius: 5px 0 5px 5px;
}

.note.rounded:before {
  border-width: 8px;
  border-color: #fff #fff transparent transparent;
  -moz-border-radius: 0 0 0 5px;
  border-radius: 0 0 0 5px;
} */
.startup-account-tab {
  margin-top: 105px;
}

.footnote-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 31px;
  text-align: center;
}

.login-div {
  margin-top: 30px !important;
}

.login-fieldset {
  min-height: 550px;
}

.close {
  color: #999999 !important;
}

.status-badge {
  padding: 4px 0px;
  border-radius: 20px !important;
  display: block;
  text-align: center;
  width: 65%;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.status-heading {
  padding-left: 30px;
}

.blue-badge {
  background-color: #e4edf4;
}

.yellow-badge {
  background-color: #fff0c0;
}
.green-badge {
  background-color: #c0ffc5;
}
.red-badge {
  background-color: #ffbfaa;
}

.grey-badge {
  background-color: #e4e2e2;
}

.status-collapse {
  border: 0px !important;
  background-color: #f8f7f7;
  padding: 15px 20px;
}

.comment-heading {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.comment-date {
  color: #555555;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}

.comment-content {
  padding-top: 10px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.application-count {
  margin-bottom: 30px;
}

.download-excel-link {
  text-decoration: none;
  color: #25429c;
}

.custom-disabled {
  opacity: 0.4;
}

.link-underline {
  text-decoration: underline;
}

.document-attched-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.url-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

/* Notifications CSS */
.app-header .notifications.dropdown-menu {
  left: inherit;
  right: -30px;
  top: 100%;
  padding: 20px 20px;
  min-width: 375px;
}

.notifications .notification-heading {
  border-bottom: 1px solid #E0E0E0;
}

.notifications .notification-heading span {
  font-size: 12px;
  cursor: pointer;
}

.notifications .menu-title {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
}

.notifications .notifications-wrapper {
  padding: 10px 0px;
  max-height: 500px;
  overflow-y: scroll;
}

.notifications .notifications-wrapper .content {
  color: #333333;
  text-decoration: none;
  display: block;
  position: relative;
  cursor: pointer;
}

.notifications .notifications-wrapper .content.unread::before {
  content: '';
  border-radius: 20px;
  border: 2px solid #fff;
  width: 12px;
  height: 12px;
  background-color: #ff6b6b;
  /* background-color: #D00C0C; */
  position: absolute;
  top: 2px;
  left: 3px;
  font-size: 8px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}

.notifications-wrapper .notification-item {
  padding-left: 30px;
}

.notifications-wrapper .notification-item .item-title {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
}

.notifications-wrapper .notification-item .item-info {
  color: #25429C;
  font-family: "Open Sans";
  font-size: 12px;
}

/* Notifications CSS */
.notification-bell {
  cursor: pointer;
}

.notification-bell+mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: -5px;
  left: 24px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}

/* Full Modal CSS */
.full-modal {
  padding-right: 0px;
}

.full-modal .modal-dialog {
  width: 100%;
  max-width: 100%;
  margin: 0px;
}

/* Full Modal CSS */

/* #customTable tr .selected-flag{
  display: none;
} */
/* #customTable .custom-disabled{
  position: relative;
  opacity: 0.6;
  z-index: -1;
  
} */
#customTable .selected-flag {
  display: block;
  /* position: absolute;
  bottom: 0px;
  left: 0px; */
  /* background-color: #FFF0C0; */
  background-color: rgba(251, 222, 129, 1);
  padding: 2px;
  color: #000000;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.selectedflagEAC {
  height: 23.45px;
  padding: 5px 11px 7px 11px;
  width: 100%;
  border-radius: 13.5px;
  background-color: rgba(251, 222, 129, 1);
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  white-space: nowrap;
}

.selectedflagEAC_BGGreen {
  height: 23.45px;
  padding: 5px 11px 7px 11px;
  width: 100%;
  border-radius: 15px;
  background-color: #63fa63;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
  margin-left: 10px;
}

/* revati */
.By_rejected_Flag {
  display: block;
  /* position: absolute; */
  /* bottom: 0px; */
  /* left: 0px; */
  /* background-color: #FFF0C0; */
  background-color: red;
  padding: 2px 5px;
  color: white;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.rejected-flag {
  display: block;
  background-color: #000000;
  padding: 2px 5px;
  color: white;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.fullClosed_flag {
  display: block;
  background-color: #ffd700;
  padding: 2px 5px;
  color: #000000;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.cancelleed_flag {
  display: block;
  background-color: #F5F5DC;
  padding: 2px 5px;
  color: #000000;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.orange-flag {
  display: block;
  background-color: #fd7e14;
  padding: 2px 5px;
  color: white;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.open-flag {
  display: block;
  /* position: absolute; */
  /* bottom: 0px; */
  /* left: 0px; */
  /* background-color: #FFF0C0; */
  background-color: #25429c;
  padding: 2px 5px;
  color: white;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

/* revati */

.Approved_flag {
  display: block;
  /* position: absolute; */
  /* bottom: 0px; */
  /* left: 0px; */
  /* background-color: #FFF0C0; */
  background-color: #60ab55;
  padding: 2px 5px;
  color: white;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  width: -webkit-max-content;
  width: max-content;
}

.bold-label {
  font-weight: 600 !important;
  font-size: 17px !important;
}

.buttonCss {
  position: fixed;
  bottom: 3em;
  right: 45%;
}

.modal-header .btn-close {
  position: absolute;
  right: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  display: flex;
  border: 0px;
}

.modal-header .btn-close::before,
.btn-close::after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}

.modal-header .btn-close:hover {
  opacity: 1;
  background: white;
}

.modal-header .btn-close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.modal-header .btn-close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}


#step_details {
  margin-left: 57px;
}

.col_inside_lable {
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  padding: 10px 0px;
  display: block;
  font-weight: 600;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.app-header .navbar-collapse .menu11 {
  min-width: 120px;
  text-align: center;
  left: -33%;
}

.Multiple_Upload_Files {
  display: inline-block;
  background-color: #f8d874;
  color: #25429c;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
  margin-top: 1rem;
  border: 1px solid #f8d874;
  border-radius: 20px;
  padding: 0.375rem 2rem
}

.btn_Download_returns {
  border-radius: 20px !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 22px !important;
}

.orange_link {
  background-color: #ffa50080;
  border-radius: 10px;
  color: #000000;
  padding: 5px;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
}

.green_link {
  background-color: #68e56880;
  color: #000000;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
}

.orange_link_header {
  background-color: #ffa50080;
  border-radius: 10px;
  color: #000000;
  padding: 5px;
  font-size: 14px;
}

.green_link_header {
  background-color: #68e56880;
  color: #000000;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
}

.orange_link_text {
  color: orange !important;
}

.orange_link_Details {
  padding: 0;
  border: none;
  color: #000000;
  background-color: #ffa50080;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 0;
  position: relative;
  height: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 8px;
}


.orange_link_Details.active::after {
  content: "";
  border-bottom: 4px solid #e26640;
  position: absolute;
  height: 4px;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 41px;
  right: auto;
}

.green_link_Details {
  padding: 0;
  border: none;
  color: #000000;
  background-color: #68e56880;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 0;
  position: relative;
  height: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 8px;
}

.green_link_Details.active::after {
  content: "";
  border-bottom: 4px solid #e26640;
  position: absolute;
  height: 4px;
  width: 100%;
  left: 0;
  bottom: 0;
  top: 50px;
  right: auto;
}

.btn-sisfs-Quaterly {
  border-radius: 8px !important;
  text-align: center;
  height: 55px;
  width: 228px;
  padding-right: 0px;
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 22px !important;
}

.topIncSelf {
  background: #f7f8ff;
  padding: 1rem;
  margin: -3% !important;
}
.about-container::-webkit-scrollbar {
  display: none;
}
.cancel-main-heading{
  background-color: #25429C !important;
  
}
.modal_close_btn{
  position: absolute;
  top: -38px;
  right: -30px;
  border: none;
  background: none;
}
.close_Btn{
  color: #ff0505;
  border-radius: 50%;
  border: 1px solid white;
  padding: 0px 10px;
  font-size: 25px;
  background-color: white;
  font-weight: 700;
  cursor: pointer;
}
.modal-body-bgclr{
  background-color: #D2EAFF70 !important;
}
.list_tags{
  color: #25429C !important;
}
.about-heading {
  padding-top: 80px;
  padding-bottom: 92px;
  box-sizing: border-box;
  height: 214px;
  border-bottom: 1px solid #efeeee;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(238, 242, 255, 0.53) 100%);
}
.Cancellation_heading{
  text-transform: capitalize;
  color: white !important;
  text-decoration: none;
  font-weight: 700;
  font-family: "Roboto Slab";
  font-size: 24px !important;
}
.cancellation_label{
  color: #25429c;
    font-weight: 700;
    font-size: 14px;
}
.tranche-head1 {
  color: #506EB9;
  font-size: 1.7rem;
  font-family: Roboto Slab;
  letter-spacing: 0.7px;
  font-weight: 700;
}

.subsqnt .s-no {
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 5px;
}

.subsqnt .section-head {
  font-size: 1rem;
  padding-left: 5px;
}

.fileDiv {
  width: 100%;
}

.required {
  color: #FF0000;
}

.subsqnt .required {
  color: #FF0000;
}

.section-bg {
  background-color: #D2EAFF;
  padding: 10px;
}
.pfmsBtns .btn-sisfs{
  font-size: 10px !important;
    padding: 1px 15px !important;
    border-radius: 0.3rem !important;
}
.bgColumn {
  height: inherit !important;
}

.sNo {
  font-weight: 600;
  font-size: 1rem;
}
.st-info{
  color: red;
   font-weight: bold;
    font-size: small;
    margin-left: 28px;
}
.sectionHead {
  font-size: 1rem;
}
.pfmsInput{
  align-items: end;
}
.regText{
  font-size: 14px;
  font-weight: 600;
  color: #506EB9;
  margin-left: 20px;
}
.otherUploads {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.alignContents {
  height: 100%;
  background-color: #F9A8A8 !important;
  border: 2px solid #FF0000;
  cursor: default !important;
}
.indicationAlign{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.indicationSection{
  display: flex;
  align-items: center;
  justify-content: end;
}
.indicationBox{
  width: 13px !important;
  height: 13px !important;
  border-radius: 50%;
  /* border: 1px solid gray; */
}
.indicationDot{
  border-radius: 50%;
}
.Rejectedbg{
  height: 100%;
  background-color: #F9A8A8 !important;
  cursor: default !important;
  /* border: 2px solid #FF0000; */
}
.Acceptedbg{
  height: 100%;
  background-color: #72F583 !important;
  cursor: default !important;
  /* border: 2px solid #1B8D2A; */
}
.Submittedbg{
  height: 100%;
  background-color: #D2EAFF !important;
  cursor: default !important;
  /* border: 2px solid #F2AF13; */
}
.Readbg{
  height: 100%;
  background-color: #A4B6FB !important;
  cursor: default !important;
  /* border: 2px solid #0B2B9F; */
}
.savedbg{
  height: 100%;
  background-color: #D2EAFF !important;
  cursor: default !important;
  /* border: 2px solid #0B2B9F; */
}
.resubmittedbg{
  height: 100%;
  background-color: #FCDC92 !important;
  cursor: default !important;
}
.Info-circle {
  background-color: yellow;
  color: black;
  padding: 0 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
}
.Info-circle2 {
  background-color: black;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
}
.Info-circle-incomplete {
  background-color: #FF0000;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: default;
  border-radius: 50%;
}
.commentsColumn{
  max-width: 100px;
  word-wrap: break-word;
  vertical-align: baseline;
}
.Info-circle-complete {
  background-color: green;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: default;
  border-radius: 50%;
}
.fileSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: space-between;
}

.acceptMark {
  font-size: 1.5rem;
  line-height: 0;
}

.rejectMark {
  font-size: 1.5rem;
  line-height: 0;
}

.rejectInput textarea {
  width: 100%;
}

.errorVal {
  border: 2px solid red;
}

.rejectInput textarea::-webkit-input-placeholder {
  text-align: center;
  padding: 10px;
}

.rejectInput textarea::placeholder {
  text-align: center;
  padding: 10px;
}

.acceptFile {
  font-size: 14px;
  color: green;
}
.acceptFile1 {
  font-size: 14px;
  color: green;
  font-weight: 700;
  cursor: default;
  margin-left: 10px;
}
.acceptFile:hover {
  -webkit-text-decoration: green underline;
          text-decoration: green underline;
}
.rejectFile1 {
  font-size: 14px;
  color: red;
  font-weight: 700;
  cursor: default;
  margin-left: 10px;
}
.rejectFile {
  font-size: 14px;
  color: red;
}

.rejectFile:hover {
  -webkit-text-decoration: red underline;
          text-decoration: red underline;
}

.Info-subhead {
  font-size: 1.3rem;
  font-weight: 600;
}
.Info-subhead1 {
  font-size: 1rem;
}
.commentsFile {
  font-size: 11px;
    background-color: #4670d994;
    color: white;
    padding: 5px 14px;
    border-radius: 15px;
    font-weight: 700;
}

.commentsFile2 {
  font-size: 8px;
  padding: 2px;
  background-color: #506EB9;
  border-radius: 50%;
  color: white;
  font-weight: 650;
}
.statusSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pointsList {
  word-break: break-all;
}

.pointlistTable th,
.pointlistTable td {
  border: 1px solid black !important;
  border-collapse: collapse;
  font-size: small;
}

.point-list .point {
  line-height: 2;
}

.susequentTable thead tr td {
  color: white;
  background-color: #506EB9;
  text-align: center;
  font-weight: 650;
}

.susequentTable tbody tr td {
  background-color: #D2EAFF70;
  text-align: center;
}

.fileUploadSec .filebox {
  background-color: white !important;
  border: dashed 2px lightgray;
}

.filebox1 {
  background-color: white !important;
  border: dashed 2px lightgray;
  border-top: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filebox1Sec {
  border-top: dashed 2px lightgray !important;
}
.uploadText {
  font-weight: 600;
}

.fileRemove {
  /* font-size: 1.5rem; */
  color: gray;
  cursor: pointer;
}

.alignContents-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-between; */
}

.removeIcon {
  font-size: large;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.alignContents-col {
  height: 100%;
}

.fileUploadSec label {
  display: inline-block;
  /* background-color: indigo; */
  /* color: white; */
  /* padding: 0.5rem; */
  /* font-family: sans-serif;
  border-radius: 0.3rem; */
  cursor: pointer;
  /* margin-top: 1rem; */
}

.filebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-heading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 45px;
  margin-bottom: 1%;
  /* font-size: 38px; */
  font-size: 36px;
  text-align: left;
}

.about-content {
  height: auto;
}

.about-content {
  padding-top: 50px;
}

.about-list {
  float: center;
  /* padding-top: 74px; */
  /* padding-left:125px; */
  padding-left: 0px;
  list-style-type: none;
}

.fixed {
  top: 135px;
  position: fixed;
}

.about-list>li>a {
  cursor: pointer;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 45px;
}

.about-list>li>a.active {
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45px;
  color: #333333;
  text-decoration: none;
}

.about-list>li>a {
  position: relative;
}

.about-list>li>a:hover {
  color: #e26640 !important;
}

.about-list>li>a.active:hover {
  color: #333333 !important;
}

.about-list>li>a.active::after {
  content: "";
  font-weight: 1000;
  color: #e26640;

  width: 8px;
  min-width: 8px;
  border-bottom: solid 2px #e26640;
  position: absolute;
  top: 0%;
  z-index: 1;
  right: -15px;
  height: 12px;
}

.right-cont {
  height: auto;
}

.right-heading {
  font-size: 28px;
  line-height: 47px;
}

.para-text {
  padding-top: 2%;
  padding-top: 2%;
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: justify;
}

.para-text-orange {
  padding-top: 2%;
  padding-top: 2%;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: justify;
}

.para-orange {
  padding-top: 1%;
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}

.about-content .img-fluid {
  max-width: 80%;
  margin-bottom: 7%;
}

.about-right-img {
  padding: 3%;
}

.about-left-img {
  margin-top: 30%;
}

.left-cont {
  padding-left: 5% !important;
}

.right-cols {
  padding-bottom: 60px;
  padding-left: 1%;
  width: 100%;
}

.right-cols:last-child {
  padding-bottom: 0px;
}

.img-sqr {
  box-sizing: border-box;
  height: 150px;
  width: 150px;
  background-color: #d9e3ec;
  margin-bottom: 5%;
}

.img-members {
  min-width: 140px !important;
  height: 130px !important;
}

.img-sqr-text {
  color: rgba(0, 0, 0, 0.18);
  font-family: "Open Sans";
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
}

.img-div {
  margin-right: 3%;
}

.img-div-row {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 30px;
}

.card-text {
  color: #555555;
  font-family: "Open Sans";
  font-weight: 400;
  width: 140px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.about-container .card-heading {
  color: #333333;
  width: 140px;
  font-family: "Open Sans";
  font-weight: 600;
  margin-bottom: 5px !important;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.ordered-list ol {
  list-style: none;
  counter-reset: item;
}

.ordered-list li {
  counter-increment: item;
  margin-bottom: 25px;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;

  font-family: "Open Sans";
}

.about-ordered-list ol {
  padding-left: 50px;
}

.about-ordered-list li:before {
  margin-right: 20px !important;
  content: counter(item);
  background: #f8d874;
  border-radius: 100%;
  color: #25429c;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  margin-left: -50px !important;
}

#myBtn {
  z-index: 99;
  font-size: 18px;
  cursor: pointer;
  color: #e26640;
  font-family: "Open Sans";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.range-container {
  position: relative;
  width: 80%;
  margin: 20px auto;
}

.range-slider {
  width: 100%;
}

.range-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}

.top-arrow {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  padding-right: 55px;
}

.objectives-nav {
  margin-bottom: 20px;
  margin-top: 20px;
}

.objectives-nav>ul.nav-tabs {
  list-style-type: none;
  padding-left: 0;
  border-bottom: none;
  display: block;
  margin-bottom: none;
  height: 38px;
  border-bottom: 1px solid #e0e0e0;
}

.objectives-nav>ul>li {
  display: inline;
  margin-right: 40px;
  line-height: 24px;
  padding-bottom: 12px;
}

.objectives-nav>ul>li>a {
  color: #555555;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
}

.objectives-nav>ul>li>a:hover {
  text-decoration: none;
  color: #333333;
  padding-bottom: 13px;
  line-height: 24px;
  cursor: pointer;
}

.objectives-nav>ul>li.active {
  display: inline;
  margin-right: 40px;
  line-height: 24px;
  /* border-bottom:4px solid #E26640; */
}

.objectives-nav>ul>li.active a {
  border-bottom: 4px solid #e26640;
  padding-bottom: 10px;
  line-height: 24px;
}

.objectives-nav>ul>li a.active {
  padding-bottom: 10px;
  line-height: 24px;
  border-bottom: 4px solid #e26640;
}

.objectives-nav>ul>li.active>a {
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
}

.faq-container {
  height: 238px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.faq-heading {
  margin-bottom: 75px;
}

.question-tab {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.question-tab .para-text {
  padding-top: 3%;
}

.question-tab .ordered-list ol {
  padding-top: 20px;
}

.question-heading {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 31px;
}

.question-heading-orange {
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 31px;
}

.hide-icon {
  width: 30px;
  height: 30px;
  margin-top: 20px !important;
  cursor: pointer;
}

.expand-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 20px !important;
}

.help-content {
  padding: 3%;
  text-align: left;
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
}

#show {
  display: none;
}

.about-ordered-list li {
  line-height: 30px;
  font-size: 18px;
  color: #333333;
}

.faq-ordered-list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.faq-ordered-list li {
  line-height: 30px;
  font-size: 18px;
}

table {
  font-family: "Open Sans";
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th {
  background-color: #fafafa;
  text-align: left;
  padding: 8px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  padding: 10px;
}

td {
  color: #333333;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

tr:nth-child(even) {
  background-color: #fafbfc;
}

.tableDataHead {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}

.tableDataApplication {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.login-nav {
  margin-bottom: 20px;
  margin-top: 30px;
}

.login-nav>ul.nav-tabs {
  list-style-type: none;
  padding-left: 0;
  border-bottom: none;
  margin-bottom: none;
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.login-nav>ul>li {
  display: inline;
  line-height: 21px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  width: 40%;
  padding-bottom: 12px;
}

.login-nav>ul>li>a {
  color: #555555;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
}

.login-nav>ul>li>a:hover {
  text-decoration: none;
  color: #333333;
  padding-bottom: 13px;
  line-height: 21px;
  cursor: pointer;
}

.login-nav>ul>li.active {
  display: inline;
  line-height: 21px;
  margin-left: 15px;
  text-align: center;
  width: 40%;
  margin-right: 15px;
  border-bottom: 4px solid #e26640 !important;
}

.login-nav>ul>li.active>a {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.incubatorCardDiv {
  margin-bottom: 15px !important;
}

/* STARTUP DETAIL DROPDOWN STYLE START */
.dropdown-statup-detail .dropdown-statup-detail-label {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  margin: 7px 0px 0px 20px;
}

.dropdown-statup-detail .fa-angle-down {
  color: #333;
  font-size: 22px;
  font-weight: 600;
}

.dropdown-statup-detail .dropdown-statup-detail-menu {
  width: 380px;
  padding: 20px 20px 5px 20px;
  z-index: 10000;
  box-shadow: 0 1px 5px 0 rgba(191, 196, 201, 0.84);
  border: none;
  margin-top: 10px;
}

.dropdown-statup-detail .dropdown-menu-list-header {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: -0.08px;
  line-height: 22px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.dropdown-statup-detail .dropdown-item-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22px;
}

.dropdown-statup-detail .dropdown-item-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  float: right;
  text-decoration: underline;
  margin: 2px 0;
}

/* STARTUP DETAIL DROPDOWN STYLE END */
/* DETAILS TABS STYLE START */
.details-tabs .nav-tabs .nav-link {
  padding: 0;
  border: none;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 0;
  position: relative;
  height: 100%;
  margin-bottom: 10px;
}

.details-tabs .nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.details-tabs .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0px;
  margin-bottom: 20px;
}

.details-tabs .nav-tabs .nav-link.active {
  font-weight: 600;
}

.details-tabs .nav-tabs .nav-link.active::after {
  content: "";
  border-bottom: 4px solid #e26640;
  position: absolute;
  height: 4px;
  width: 100%;
  left: 0;
  bottom: 0;
  top: auto;
  right: auto;
}

.details-tabs .details-tabs-content .tab-pane-header {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  border-bottom: 1px solid #e0e0e0;
  line-height: 21px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.details-tabs .details-tabs-content .tab-pane-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 5px;
}

.details-tabs .details-tabs-content .tab-pane-label-value {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.details-tabs .details-tabs-content .tab-pane-value-year-time {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.details-tabs .details-tabs-content .tab-pane-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.details-tabs .details-tabs-content .tab-pane-label-feedback {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.cancellation-comment-tab .tab-pane-label-feedback {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.cancellation-comment-tab .tab-pane-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.cancellation-comment-tab .tab-pane-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.details-tabs .details-tabs-content .tab-pane-label-group {
  margin-bottom: 20px;
}

.details-tabs .details-tabs-content .tab-pane-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.details-tabs .details-tabs-content hr {
  border-top: 1px solid #e0e0e0;
  margin-top: 0px;
}

.details-tabs .details-tabs-content .tab-pane-label-small {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.details-tabs .details-tabs-content .tab-pane-value-highlight {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 25px;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-count {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 25px;
  letter-spacing: 0;
  line-height: 34px;
}

.details-tabs .details-tabs-content .tab-pane-total {
  margin-top: 20px;
}

.details-tabs .details-tabs-content .tab-pane-total.tab-pane-total-spacing {
  border-right: 1px solid #e0e0e0;
  margin-right: 25px;
  padding-right: 25px;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-count span {
  font-size: 38px;
  font-weight: 600;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

/* DETAILS TABS STYLE END */
/* DETAILS ACCORDION STYLE START */
.accordion-details-wrap .accordion-card {
  border-radius: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #e0e0e0 ;
}

.newIcon { 
  font-size: 5em; 
  font-family: serif; 
  color: #008000; 
  text-align: center; 
  -webkit-animation: animate 0.8s ease-in-out infinite; 
          animation: animate 0.8s ease-in-out infinite; 
} 

@-webkit-keyframes animate { 
  0% { 
      opacity: 0; 
      -webkit-transform: scale(0.9); 
              transform: scale(0.9); 
  } 

  50% { 
      opacity: 0.7; 
      -webkit-transform: scale(1.1); 
              transform: scale(1.1); 
  } 

  100% { 
      opacity: 0; 
      -webkit-transform: scale(0.9); 
              transform: scale(0.9); 
  } 
} 

@keyframes animate { 
  0% { 
      opacity: 0; 
      -webkit-transform: scale(0.9); 
              transform: scale(0.9); 
  } 

  50% { 
      opacity: 0.7; 
      -webkit-transform: scale(1.1); 
              transform: scale(1.1); 
  } 

  100% { 
      opacity: 0; 
      -webkit-transform: scale(0.9); 
              transform: scale(0.9); 
  } 
} 

.accordion-details-wrap .accordion-card .accordion-card-header {
  background: transparent;
  border: none;
  margin: 0;
  padding: 15px 0;
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  cursor: pointer;
}

.accordion-details-wrap .accordion-card .accordion-card-header span {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 15px;
}

.accordion-details-wrap .accordion-card .accordion-card-header:after {
  content: "";
  background-image: url(/static/media/Minus.224d1bfb.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 21px 21px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-header.collapsed,
.accordion-details-wrap .accordion-card .accordion-card-header.collapsed span {
  color: #333;
}

.accordion-details-wrap .accordion-card .accordion-card-header.collapsed:after {
  content: "";
  background-image: url(/static/media/Plus.04054765.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 21px 21px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body {
  padding: 10px 30px 15px 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group {
  margin-bottom: 25px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
  text-align: left;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label-highlight {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label-big {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-icon {
  margin-right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-info-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-pdf-icon::after {
  content: "";
  background-image: url(/static/media/PDF.54e0cc8f.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 46px;
  height: 45px;
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-xls-icon::after {
  content: "";
  background-image: url(/static/media/PDF.54e0cc8f.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 46px;
  height: 45px;
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-info {
  position: relative;
  margin-top: 20px;
  padding-left: 55px;
  min-height: 48px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-list {
  padding-left: 20px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-document .document-img {
  margin-right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .tab-pane-link-view {
  text-decoration: none;
}

/* DETAILS ACCORDION STYLE END */

/*PORTFOLIO CSS START*/
.portMainHeading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 15px;
  font-size: 30px;
  text-align: left;
  font-weight: 400;
}

.portFoundText {
  font-weight: 600;
  font-size: 18px;
}

.portFolioGrid {
  border: 1px solid #e7e7e7;
  margin-top: 25px;
}

.portContentBlock {
  padding: 10px 15px;
}

.portCity {
  margin-bottom: 5px;
  font-size: 13px;
}

.portImage {
  width: 100%;
  /* min-height: 185px; */
  height: 185px;
}

.portLogo {
  height: 75px;
  object-fit: contain;
}

.portImageMain {
  width: 100%;
  object-fit: contain;
}

.portHeading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  min-height: 48px;
  max-height: 48px;
}

.portDescription {
  margin-bottom: 15px;
}

.portTag {
  display: contents;
}

.portTagDetail span {
  border: 1px solid #e7e7e7;
  padding: 0px 15px;
  border-radius: 50px;
  font-size: 13px;
  line-height: 32px;
  margin-bottom: 10px;

}

.portTag span {
  border: 1px solid #e7e7e7;
  padding: 5px 15px;
  border-radius: 50px;
  font-size: 13px;
  line-height: 32px;
  margin-bottom: 10px;
}

.twoLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.portLink {
  color: initial;
  text-decoration: none;
}

.portLink:hover {
  color: initial;
  text-decoration: none;
}

.portDetailHeading {
  font-weight: 500;
  font-size: 28px;
}

/*PORTFOLIO CSS END*/

/* tooltip css */
[data-md-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-md-tooltip]:before {
  content: attr(data-md-tooltip);
  position: absolute;
  bottom: -35px;
  left: 50%;
  padding: 8px;
  -webkit-transform: translateX(-50%) scale(0);
          transform: translateX(-50%) scale(0);
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -webkit-transform-origin: top;
          transform-origin: top;
  background: #616161e6;
  color: white;
  border-radius: 2px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

[data-md-tooltip]:hover:before {
  -webkit-transform: translateX(-50%) scale(1);
          transform: translateX(-50%) scale(1);
}

[data-md-tooltip].md-tooltip--right:before {
  left: auto;
  right: -10px;
  bottom: auto;
  top: 50%;
  -webkit-transform: translate(100%, -50%) scale(0);
          transform: translate(100%, -50%) scale(0);
  -webkit-transform-origin: left center;
          transform-origin: left center;
}

[data-md-tooltip].md-tooltip--right:hover:before {
  -webkit-transform: translate(100%, -50%) scale(1);
          transform: translate(100%, -50%) scale(1);
}

[data-md-tooltip].md-tooltip--left:before {
  left: -15px;
  right: auto;
  bottom: auto;
  top: 50%;
  -webkit-transform: translate(-100%, -50%) scale(0);
          transform: translate(-100%, -50%) scale(0);
  -webkit-transform-origin: right center;
          transform-origin: right center;
}

[data-md-tooltip].md-tooltip--left:hover:before {
  -webkit-transform: translate(-100%, -50%) scale(1);
          transform: translate(-100%, -50%) scale(1);
}

.md-tooltip--top:before {
  top: -85px;
  bottom: auto;
  -webkit-transform: translateX(-50%) scale(0);
          transform: translateX(-50%) scale(0);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  z-index: 9999;
}

/* tooltip css End*/
.bs-stepper-header a {
  color: #25429C !important;
}
.dashboard {
  height: 20px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.dashboard_inc {
  /* height: 20px; */
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 20px; */
}
.dashboard_Application {

  height: 20px;

  color: #333333;

  font-family: "Roboto Slab";

  font-size: 22px;

  font-weight: 600;

  letter-spacing: 0;

}
.itemsPerPage{
  color: #25429c;
  font-size: 15px !important;
}
.itemsPerPageSelect{
  border: none;
    border-bottom: 3px solid #dc3545;
    color: #25429c;
    font-weight: 600;
    font-size: 15px;
}
.pagination a:not([href]):not([class]){
  display: none !important;
  font-size: 18px !important;
}
.pageNum{
  font-size: 15px;
  color: #25429c;
  margin-bottom: 3px;
}
.pagination [role=button]{
  font-size: 15px !important;
  font-weight: 600;
  padding: 3px !important;
  margin-right: 1px !important;
  color: #25429c !important;
  /* background-color: #f8d874; */
}
.group-2 {
  height: 163px;
  width: 940px;
}
.full-width {
  width: 100% !important;
}
.rectangle-1 {
  height: 163px;
  width: 210px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 10px 0px;
  text-align: center;
}
.rectangle-2 {
  height: 130px;
  width: 190px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 10px 0px;
  text-align: center;
}
.rectangle-incubator {
  height: 163px;
  width: 290px;
  border-radius: 20px;
  background-color: #f7f8ff;
  /* margin: 10px; */
  margin: 10px 0px;
  text-align: center;
}

.rectangle-card-selected-orange {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #ffa50080;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.rectangle-card-selected-green {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #68e56880;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-selected {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-selected-bottomBox {
  height: 55px;
  width: 300px;
  border-radius: 10px;
  background-color: #f8d874;
  margin: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 4px;
  box-shadow: 5px 5px 10px #808080a1;
}

.rectangle-card {
  height: 113px;
  width: 106px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-bottom {
  height: 55px;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 4px;
}

.rectangle-card:hover, .rectangle-card-bottom:hover {
  border: 1px solid #25429c;
}

.incubator-numeric {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 70px;
  cursor: default;
}
.smallIncubatorNumeric {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: justify;
}

.smallIncubatorNumeric_bottom_bukket {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: justify;
}

.blue-arrow {
  margin-left: 5px;
}
.incubator-text-selected-small {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.incubator-text-selected-small-buttom {
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-right: 40px;
}

.incubator-numeric-black {
  color: #999999;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}

.incubator-numeric-black-buttom-bucket {
  color: #999999;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}
.reviewed-count{
  line-height: 38px !important;
}
.reviewed-text{
  font-size: 11px !important;
}

.rectangle-card:hover .incubator-numeric-black,
.rectangle-card:hover .card-text-incubator,
.rectangle-card:hover .card-text-incubator-buttom,
.rectangle-card:hover .incubator-numeric-black-buttom-bucket {
  color: #25429c;
}
.tool-tip{
  position: relative;
}
.tool-tip::after {
  content: "Startups puts in this bucket will not stop the 45 days period of evaluation from expiring.";
  position: absolute;
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  -webkit-transform: translateX(-50%,-110%); 
          transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.fileToolTip .tool-tip{
  position: relative;
}
.fileToolTip .tool-tip::after {
  content: ".png/.jpg/.jpeg images only";
  position: absolute;
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  -webkit-transform: translateX(-50%,-110%); 
          transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.fileToolTip .tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.emailToolTip .tool-tip{
  position: relative;
}
.emailToolTip .tool-tip::after {
  content: "This email ID will be published on the Portfolio Page of the SISFS website,which will be publicly visible to all visitors of the incubator’s page";
  position: absolute;
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  -webkit-transform: translateX(-50%,-110%); 
          transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.emailToolTip .tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.card-text-incubator {
  color: #999999;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
}

.card-text-incubator-buttom {
  color: #999999;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
  margin-right: 40px;
}

.box {
  width: 220px;
  height: 163px;
  margin-left: 10px;
}
.footer-2 {
  height: 71px;
  width: 1280px;
}

.rectangle-copy-3 {
  height: 71px;
  background-color: #fdfbfb;
}

.dashboard-footer-left {
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  padding-left: 3%;
  text-align: left;
}
.fa-calendar {
  color: #25429c;
}
.group-17 {
  height: 16px;
  width: 319px;
}

.footer-text {
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  text-align: right;
}
.modal-title {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 47px;
}
.modal-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}
.view-button {
  height: 17px;
  width: 90px;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
  text-decoration: underline;
}
.view-button:hover {
  color: #e26640;
}

div.hr {
  height: 15px;
  background: #fff no-repeat scroll center;
  /* margin-left: 16px;
  margin-right: 16px; */
  margin-top: -15px;
}

.group {
  height: 57px;
  width: 58px;
}

.cardDiv {
  margin-bottom: 30px;
}

.chartDuration {
  /* height: 17px;
  width: 19px; */
  cursor: pointer;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-decoration: none;
  margin: 10px 4px;
  padding: 6px;
  min-width: 30px;
  display: inline-block;
}
.chartDurationTill:hover,
.chartDurationTill.active {
  background-color: transparent !important;
  text-decoration: underline;
  color: #e26640;
}

.chartDuration:hover {
  /* height: 50px;
  width: 50px; */
  background-color: #f8d874;
  border-radius: 50%;
}
.chartDuration.active {
  /* height: 50px;
  width: 50px; */
  background-color: #f8d874;
  border-radius: 50%;
}
.dashboard-heading-secion .chartDuration {
  margin: 5px 7px;
  padding: 8px 6px;
  min-width: 34px;
  display: inline-block;
}
.calendar-icon {
  margin: 0px 5px;
  border-left: 1px solid #dddddd;
  padding-left: 15px;
  cursor: pointer;
}

.scheduleMeeting {
  height: 450px;
  /* width: 300px !important; */
  border-radius: 20px;
  background-color: rgba(248, 216, 116, 0.25);
  box-sizing: initial;
  margin-left: 14px;
}

.upcoming-meeting {
  height: 21px;
  width: 147px;
  color: #25429c;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 50px;
}
.lineChartDiv {
  margin: 0 !important;
}
.modal-header-bottom {
  border-bottom: 0px !important;
  padding-right: 65px !important;
}
.input-height {
  height: 46px !important;
}
.view-all {
  height: 17px;
  width: 47px;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 50px;
  text-decoration: underline;
  cursor: pointer;
}
.view-all:hover {
  color: #e26640;
  text-decoration: underline;
}
.pending-count-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  margin-top: 5px;
  margin-bottom: 0;
}
.tertiary {
  width: 177.17px;
  color: #25429c;
  font-family: "Open Sans" !important ;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 20px !important;
  padding: 10px;
}

.centerText {
  text-align: center;
}

.eac-performance-revi {
  height: 38px;
  width: 154px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
}

.eacMeetTime {
  height: 21px;
  width: 144px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.eacDateNumber {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  margin-right: 6px;
}

.eacDateMonth {
  height: 21px;
  width: 46px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}
/* .hori-bar.chartjs-size-monitor.chartjs-render-monitor{
  height: 250px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
} */

.rectangle {
  /* height: 400px; */
  margin-top: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #ccd1e0;
  padding-top: 12px;
}
.duration-bottom {
  border-bottom: 0.5px solid #c9c9c9;
  text-align: center;
  padding-bottom: 5px;
}
.rectangle-incubator-bar {
  height: 510px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #ccd1e0;
}
.review-calendar-icon {
  margin: 0px;
}
.incubator-applicatio {
  color: #333333;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.burns-industries {
  height: 20px;
  width: 122.02px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.card-count {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
}
.count-bg {
  font-size: 38px !important;
  font-weight: 600 !important;
}

.mumbai-maharashtra {
  height: 17px;
  width: 127.1px;
  color: #777777;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.eac-review {
  height: 100%;
  width: 35%;
}
.eac-review-wrapper {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
  height: 100%;
  position: relative;
}
.eac-review-wrapper .eac-review-heading {
  font-size: 30px;
  color: #2b408b;
}
.eac-review-wrapper .close {
  position: absolute;
  top: 0px;
  right: 20%;
  font-size: 42px;
  font-weight: 500;
}
.eac-review-wrapper .form-control {
  padding: 20px 0.75rem;
  color: #777777;
}
.eac-review-wrapper .btn-sisfs {
  padding: 10px 20px !important;
}

.eac-review-meeting {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.chart-nav > li > .nav-link {
  color: #999999;
  font-family: "Roboto Slab";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}
.chart-nav > li > .nav-link.active {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  border-color: white;
}
.nav-tabs.chart-nav {
  border-bottom: 0;
}
.vertical-dash {
  width: 2px;
  height: 18px;
  margin-top: 12px;
  background-color: #999999;
}
.tab-content {
  margin-top: 21px;
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(125%, 0px);
          transform: translate(125%, 0px);
  width: 500px;
  height: 100%;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 0;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 500px;
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

#exampleModal1.modal.fade.show .modal-dialog.modal-dialog-slideout {
  width: 500px !important;
}
.searchBar {
  padding: 10px 10px 10px 30px;
  margin-left: -25px;
  width: 330px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background-color: #fcfcfc;
}
.searchBar:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.search {
  z-index: 1;
}
.searchDiv {
  margin-right: 0;
  padding-left: 30px;
}
.count-text-incubator {
  color: #333333;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
}
.incubator-heading-div {
  margin-bottom: -2px;
  flex-wrap: nowrap !important;
}
.incubator-heading-review {
  margin-bottom: 20px;
}
table th,
table td {
  /* Use CSS variables to calculate equal width for n columns */
  width: 200px;
  /* Add other styling if needed */
}
.table-head {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}
.count-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
.col-form-head {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22px;
}
.table-heading {
  color: #333333;
  padding: 25px 10px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  position: -webkit-sticky;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 2;
}
.tableDataHead {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}
.tableDataApplication {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.badge-red {
  height: 23.45px;
  padding: 0 0 5px 5px;
  width: 100%;
  border-radius: 13.5px;
  background-color: #ffbfaa;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}
.badge-grey {
  height: 24px;
  padding: 0 0 5px 5px;
  border-radius: 13.5px;
  background-color: #c4d2f4;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.badgeGrey .badge-grey{
  height: 24px;
  padding: 0 0 5px 5px;
  border-radius: 13.5px;
  background-color: #c4d2f4;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  width: 200px;
  margin: auto;
}
.checkbox-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  padding-top: 4px;
  letter-spacing: 0;
  line-height: 24px;
}
.back-slash::before {
  content: "/";
  color: #555555;
}
.search-left {
  padding-left: 105px !important;
}
.search-left-meeting {
  padding-left: 0 !important;
}
.custom-td {
  /* min-width: 180px !important; */
  padding-left: 10px;
  word-wrap: break-word;
}
.custom-td-small {
  min-width: 120px !important;
  padding-left: 10px;
}

.Before_FiveDays {
  min-width: 120px !important;
  padding: 6px;
  border-radius: 6px;
  /* margin-right: 12px; */
  background-color: orange;
}

.custom-tdd-small {
  min-width: 140px !important;
  /* padding-left: px; */
}
.custom-td-left {
  min-width: 180px !important;
  padding-left: 18px;
}
.filter-icon {
  padding-left: 0px !important;
}
.filter {
  cursor: pointer;
}
.table-scroll {
  height: 500px;
  overflow: auto;
  width: 100%;
}
.table-scroll::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}
.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
.radio-popup {
  margin-bottom: 20px;
}
.orange-link {
  color: #ff8962;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
@media (min-width: 576px) {
  .modal-dialog {
    /* max-width: 700px !important; */
    margin: 1.75rem auto;
  }
}
.radio-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 2%;
}
.dahboard-card-heading {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 14px;
  margin-bottom: 0px;
  padding-left: 25px;
}
.link-header {
  padding: 0 !important;
  height: 40px;
}
.top-heading-dash {
  padding-bottom: 15px;
}
.message-orange {
  color: #e26640 !important;
}
.textarea-height {
  height: 117px !important;
}
.edit-column {
  cursor: pointer;
}
.btn-yellow-disabled {
  opacity: 0.6;
}
.custom-td-right {
  padding-right: 37px !important;
}
#filterModal .ms-wrapper .form-select {
  height: 46px !important;
  line-height: 2;
}
#exampleModalCenter .modal-body {
  padding: 20px 35px !important;
}
#exampleModalCenter .modal-header {
  padding: 15px 35px !important;
}
#exampleModalCenter .report-list {
  padding: 15px 0px;
  min-height: 75px;
  border-top: 1px solid #e3e3e3;
}
#exampleModalCenter .report-list:first-child{
  border-top: none;
}
#exampleModalCenter .report-title {
  border-bottom: 2px solid #999999;
}
.reviewTable {
  margin-top: 0 !important;
}
.reviewTable .btn-sisfs {
  padding: 0.275rem 1.5rem !important;
}
.filter-icon mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: -5px;
  left: 24px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.filter-icon1 mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: 5px;
  left: 50px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.filter-icon2 mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: 5px;
  left: 39px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.modal-row {
  display: contents !important;
}
.custom-td-last {
  padding-left: 0px !important;
}
.custom-td-last-head {
  padding-left: 0px !important;
  min-width: 180px !important;
}
.custom-td-width {
  min-width: 180px !important;
  padding-left: 10px;
}
#dashboard_line_chart canvas {
  /* height: 340px !important; */
  height: 100% !important;
}
#hori-bar canvas {
  height: 250px !important;
}
.count-small {
  font-size: 24px !important;
}
.error-wrapper {
  min-height: 400px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
}
.error-text {
  margin: auto;
  font-size: 50px;
  color: #e26640;
}
.upcomingMeetingClick {
  cursor: pointer;
}
.list-dashboard ul {
  list-style-type: none;
  padding-left: 0;
  border-top: 1px solid #e3e3e3;
}
.list-dashboard li {
  padding: 10px 10px;
}
.list-scroll {
  height: 200px;
  overflow: auto;
  width: 100%;
}
.schedule-button-pd {
  padding: 0 !important;
}
.dashboardContainer {
  padding-bottom: 40px !important;
}
#customTable th:last-child {
  min-width: 180px !important;
}
.searchUser {
  width: 100% !important;
}
.grievanceTable .btn-sisfs {
  padding: 0.275rem 15px !important;
}
.breadcrumb-wrapper .breadcrumb {
  background-color: transparent;
  padding-left: 0 !important;
  margin-bottom: 0px;
}
.breadcrumb-wrapper .breadcrumb li.active {
  color: #333333;
  font-weight: 600;
}
.grievance-chat-wrapper{
  padding:20px 0px;
  position: static;
}
.breadcrumb-wrapper .breadcrumb li,
.breadcrumb-wrapper .breadcrumb li a,
.breadcrumb-wrapper .breadcrumb li a:hover {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: none;
}
.grey-box {
  background-color: #f8f7f7;
  padding: 20px 20px;
  margin-bottom: 5px;
}
.grey-box .box-heading .box-main-heading {
  color: #2b408b;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}
.grey-box .box-heading .box-main-subheading {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
}
.grey-box .box-heading {
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}
.grey-box .box-content .content-list {
  padding: 10px 0px;
  word-break: break-all;
}
.grey-box .box-content {
  padding: 10px 0px 0px;
}
.grey-box .box-content .content-list .label-heading {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.grey-box .box-content .content-list .content-value {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.grey-box .box-content .content-list .content-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}
.grey-box .box-content .content-list .status-badge {
  width: 100% !important;
}
.grey-box .box-content .content-list .status-badge b{
  text-transform: capitalize;
}
.grey-box .box-content .content-list .document-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  overflow-wrap: anywhere;
}
.grievance-chat-wrapper .chat-heading .grievance-head {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.grievance-chat-wrapper .chat-heading {
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 20px;
}
.chat-content .media-chat {
  text-align: right;
  width: 75%;
  margin-left: auto;
}
.chat-content .media-chat .media-body {
  background-color: #f7f8ff;
  padding: 15px 15px;
}
.chat-content .media-chat-reverse {
  text-align: left;
  width: 100%;
  margin-right: auto;
}
.chat-content .media-chat-reverse .media-body {
  background-color: transparent;
  border: 1px solid #dddddd;
  padding: 15px 15px;
}
.chat-content .chat-list { 
  padding: 15px 0px;
}
#chat-content{
  overflow-y: scroll !important;
  height:70vh !important;
  overflow-x: hidden;
  padding:0px 15px;
}
.chat-action .content-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: none;
}
.chat-action{
  padding:20px 0px;
  border-top:1px solid #e0e0e0;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
}
.grievanceTable thead th:first-child{
  width: 25%;
}
.grievanceTable thead th:last-child{
  width: 15%;
}
.grievanceTable tbody td:last-child{
  padding-left: 5px;
  padding-right: 5px;
}
.grievance-chat-wrapper .send-link{
cursor: pointer;
}
.contactUsTable thead th:last-child{
  width: 45%;
}
.contactUsTable .link-orange-text{
  cursor: pointer;
  font-size: 13px;
}

.slider-td .switch {
  position: relative;
  display: inline-block;
  width: 50px;
    height: 22px;
    margin-bottom: 0px;
}

.slider-td .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-td .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider-td .slider:before {
  position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    /* bottom: 4px; */
    top: 3px;
    background-color: white;
    transition: .4s;
}

.slider-td input:checked + .slider {
  background-color: #c4d2f4;
}

.slider-td input:focus + .slider {
  box-shadow: 0 0 1px #c4d2f4;
}

.slider-td input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-td .slider.round {
  border-radius: 34px;
}

.slider-td .slider.round:before {
  border-radius: 50%;
}
.userListTable thead th:last-child{
  z-index: 9;
}
.grievanceTable thead th:last-child{
  z-index: 99;
}
.startup-listing-wrapper .rectangle-card{
  width: 93px !important;
}
.startup-listing-wrapper .rectangle-card-selected{
  width: 93px !important;
}
.small-font-badge{
width: 80% !important;
}
.nopadding{
  padding: 0 !important;
  margin: 0 !important;
}
#carouselExampleIndicators{
  width: 80%;
  margin: auto;
}
#carouselExampleIndicators .carousel-item{
  max-height: 200px !important;
}
#carouselExampleIndicators .carousel-control-prev-icon {
  background-image: url(/static/media/LeftArrow.39b486e1.svg) !important;
}
.galleryCarousel #carouselExampleIndicators .carousel-control-prev {
  left: -60px !important;
}
.galleryCarousel #carouselExampleIndicators .carousel-control-next {
  right: -60px !important;
}
#carouselExampleIndicators .carousel-control-prev {
  left: -80px !important;
}
#carouselExampleIndicators .carousel-control-next {
  right: -80px !important;
}
#carouselExampleIndicators .carousel-control-next-icon {
  background-image: url(/static/media/RightArrow.afe14ad8.svg) !important;
}
.portfolio-container .about-heading{
  padding-top: 65px;
  height: 175px;
}
.portfolio-detail-container .mainImage{
  margin-left: -10px;
}
.statusTable .table-heading{
  line-height: 0px;
  background-color: #f8f7f7;
}
.statusTable td{
  line-height: 20px;
  background-color: #f8f7f7;
}

/* switch button for Quaterly Review*/



.custom-td1 {
  /* min-width: 180px !important; */
  padding-left: 10px;
}
.slider-td1 .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
  margin-bottom: 0px;
}
.slider-td1 .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider-td1 .slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e26640;
  transition: .4s;
}

.slider-td1 .slider1:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  /* bottom: 4px; */
  top: 3px;
  background-color: white;
  transition: .4s;
}

.slider-td1 input:checked + .slider1 {
  background-color: #68e568;
}

.slider-td1 input:focus + .slider1 {
  box-shadow: 0 0 1px #c4d2f4;
}



.slider-td1 input:checked + .slider1:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}



/* Rounded sliders */
.slider-td1 .slider1.round1 {
  border-radius: 34px;
}



.slider-td1 .slider1.round1:before {
  border-radius: 50%;
}



.qrtable .table-heading1 {
  text-align: center;
  color: #333333;
  padding: 25px 10px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  position: -webkit-sticky;
  position: sticky;
  top: 0; 
  z-index: 2;
}
.qrtable .table-heading1{
border-bottom: none!important;
  border-top: none!important;
}
.qrtable td{
  border-top: none!important;
  border-bottom: none!important;
}
.quaterly_rectangle_card {
  height: 123px;
  width: 115px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}



.quaterly_rectangle_card_selected {
  height: 123px;
  width: 115px;
  border-radius: 20px;
  background-color: #f7f8ff;
  /* border: 1px solid #dddddd; */
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
}


.quaterly_rectangle_card_new {
  /* height: 131px; */
  width: 155px;
  border-radius: 14px;
  background-color: #f7f8ff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  /* padding-top: 10px;
  width: 155px; */

  padding: 20px;
  width: 280px;
}


.card-text-incubator_new {
  color: #4d4a4a;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
}

.incubator-numeric-black_new {
  color: #2b3f89;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}









.incubators-name-heading {
  height: 30px;
  width: 248px;
  color: #a36363;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;

}

.incubators-title {
  font-family: "Open Sans";
  color: #E26640;
  height: 30px;
  /* width: 248px; */
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.incubator-rectangle {
  height: auto;
  width: 300px;
  background-color: #F8F7F7;
  padding-bottom: 10px;
}

.incubator-rectangle-2 {
  /* height: 267px; */
  /* width: 300px; */
  background-color: #F8F7F7;
}

.incubator-label {
  height: 17px;
  /* width: 127.1px; */
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap
}


.incubator-label-heading {

  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 16px;
  font-weight: bold;
}
.seperator{
  /* width: 25vw; */
  /* margin-left: 0; */
  border-bottom: 1px solid #dcdedf;
}
.incubator-label-wrap {
  height: 17px;
  width: 127.1px;
  font-weight: bold;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 15;
  line-height: 17px;
}

.incubator-label-value {
  height: 20px;
  width: 115px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  white-space: inherit;
}
.incubator-label-value input[type="text"]{
  /* background: transparent !important; */
    border: none !important;
}
.incubator-email-value {
  height: 30px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 30px;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

.incubator-name-value {
  height: 20px;
  width: 115px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;

  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap
}

.box-overflow {
  min-height: 205px;
  width: 280px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 1px rgba(157, 162, 166, 0.61);
  overflow: auto;
}

.ul-b {
  list-style-type: circle;

}


.ul-ml {
  margin-left: -1.5em;
}


.incubator-label-value-wrap {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  word-break: break-all;
}

.website-link {
  height: 20px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
}

.document-label-value-wrap {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  word-break: break-word;
}

.document-label-value {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  /* margin-left:15px; */
}

.incubator-name {
  height: 27px;
  /* width: 190px; */
  color: #2B408B;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 0px;
}

.eac-name-pop-up {
  height: 28px;
  /* width: 218px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}

.tab-header {
  height: 24px;
  width: 81px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.incubator-hr {
  /* height: 15px; */
  margin-left: 0px;
  margin-right: 10px;
}


.line-height-24 {
  line-height: 24px;
}

.group-10 {
  height: 168px;
  width: 256px;
}

/* .group-11 {
  width: 200px;
} */

.eac-heading-title {
  height: 21px;
  width: 109px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
}

.left-align {
  text-align: left;
}

.mt-3-px {
  margin-top: 3px !important;
}

.ml-21-px {
  margin-left: 21px !important;
}

.mt-15-px {
  margin-top: 15px !important;
}



.ml-18px {
  margin-left: 18px;
}

.ml-20px {
  margin-left: 20px;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}


.modal-header-border-none {
  border-bottom: none !important;
}

.center-align {
  text-align: center;
}

.meeting-heading-subtitle {
  height: 21px;
  width: 109px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.div-disable {
  pointer-events: none;
}

/* .eac-review-subtitle {
  height: 24px;
  width: 97px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  text-align: left;
} */

.eac-incubator-hr {
  height: 15px;
  margin-left: 0px;
  margin-right: 0px;
}

.application-incubator-hr {
  margin-left: 0px;
  margin-right: 0px;
}

.eac-review-title {
  height: 20px;
  width: 255px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.eac-review-subtitle {
  height: 17px;
  width: 161px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
}

.eac-p {
  height: 63px;
  width: 427px;
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.incubator-details-link {
  height: 30px;
  width: 81px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

.incubator-details-link:hover {
  color: #E26640;
}

.incubators-title:hover {
  color: #E26640;
}

.incubator-email-value:hover {
  color: #E26640;
}

.incubator-link-number:hover {

  text-decoration: none;

}

.eac-rectangle {
  height: 90px;
  width: 139px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 #CCD1E0;
}

.incubator-link-number {
  height: 20px;
  width: 22px;
  color: #25429C;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.mt-05 {
  margin-top: 0.5rem;
}


.mt-1-5 {
  margin-top: 1.5rem;
}



.ml-1-5 {
  margin-left: 1.5rem;
}

.mr-1-5 {
  margin-right: 1.5rem;
}

.vl-mr {
  margin-right: 10px;
  margin-left: 10px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}


.margin-16 {
  margin: 16px;
}

.margin-20 {
  margin: 20px;
}

.margin-35 {
  margin: 35px;
}


.margin-26 {
  margin: 26px;
}


.margin-left-20 {
  margin-left: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-3 {
  margin: 3px;
}

.top-margin-10 {
  margin-top: 10px;
}


.margin-bottom-24 {
  margin-bottom: 24px;
}


.margin-bottom-10 {
  margin-bottom: 10px;
}


.eac-member {
  height: 15px;
  width: 67px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap;
}

.eac-member-name {
  height: 20px;
  width: 54px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 600;
  white-space: nowrap;
}

.label-lh-28 {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap
}

.label-lh-28-wrap {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;

}


.label-lh-30 {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap
}

.value-lh-20 {
  height: 120px;
  width: 202px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  white-space: wrap !important;
  word-break: break-all;
}

.reports-heading {
  height: 21px;
  width: 125px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.fund-amount-label-value {
  /* height: 52px; */
  color: #25429C;
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 29px;
  font-weight: 600;
  margin-right: 10px;
}


.fund-amount-cur {
  height: 52px;

  color: #25429C;
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 29px;

}

.fund-amount-label {
  height: 22px;
  width: 141px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}
.fund-amount-label-new{
  height: 22px;
  /* width: 141px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  /* line-height: 22px; */
  /* white-space: nowrap; */
}
.vl {
  border-left: 1px solid #E0E0E0;
  height: 102px;
}

.mb-12 {
  margin-bottom: 12px;
}


.mt-7 {
  margin-top: 7px;
}

.tranche-heading {
  height: 21px;
  width: 72px;
  color: #E26640;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.tranche-subheading {
  height: 20px;
  width: 274px;
  color: #E26640;
  font-family: "Roboto Slab";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}


.reports-subheading {
  height: 20px;
  width: 274px;
  color: #25429C;
  font-family: "Roboto Slab";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}


.fund-label {
  height: 40px;
  width: 290px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.tranch-details-heading {
  height: 47px;
  width: 195px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 47px;
  white-space: nowrap;
}

.tranche-details-box {
  height: 592px;
  width: 775px !important;
}

.tranche-label {
  height: 22px;
  width: 141px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.tranche-link {
  height: 19px;
  width: 140px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.tranche-label-bold {
  height: 34px;
  width: 101px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.tranche-label-normal {
  height: 17px;
  width: 69px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.tranche-row-header-grey {
  height: 68px;
  width: 720px;
  background-color: #F8F7F7;
}

.tranche-row-grey {
  height: 69px;
  width: 734px;
  background-color: #FAFBFC;
}


.tranche-row {
  height: 69px;
  width: 734px;
}



.tranche-label {
  height: 17px;
  width: 69px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  white-space: nowrap
}

.tranche-stepper-button {
  border: 0px;
  background: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
} */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 0%;
  margin-left: -50px;

  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


.tooltip-overwrite {
  opacity: 1 !important;
  /* position: unset !important; */
  margin: 4px !important;
}

.visible-hide {
  visibility: hidden;
}

.general-label-value {
  width: 202px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;


}

.general-label {
  width: 265px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
}


.general-label-heading {

  color: #555555;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  font-weight: bold;
}


.general-label-nowrap {
  width: 265px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  white-space: nowrap;
}


.this.state.incubatorDetailsModel.submitted_at {
  width: 265px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  white-space: nowrap;
  font-weight: bold;
}

.nav-link.active incubator {
  border-bottom: 4px solid #e26640;
  color: rgb(85, 85, 85);
}


.nav-incubator {
  height: 24px;
  /* width: 81px; */
  color: #555555;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 0px !important;
}


.hide-icon-details {
  margin-top: 0px !important;
}

.expand-icon-details {
  margin-top: 0px !important;
}

.chart-nav>li>.nav-incubator.active {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 0px !important;
  border-color: white;
}


.incubator-address {
  height: 28px;
  width: 125px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
}

.chart-nav>li>.nav-incubator {
  height: 42px !important;
}

.chart-nav>li>.nav-incubator.active {
  border-bottom: 4px solid #e26640 !important;
  border: 0px;
}

.chart-nav .nav-item {
  padding: .0px 1rem;
}

.chart-nav .nav-link {
  padding: .5rem 0px;
}

.chart-nav>li>.nav-incubator:hover {
  border-bottom: 4px solid transparent;
}

.margin-top-minus-17 {
  margin-top: -17px;
}


.margin-top-1 {
  margin-top: 1px !important;

}


.padding-top-12 {
  padding-top: 12px !important;

}

.group-3 {
  height: 106px;
  width: 142px;
}

#tranchestepper .step .bs-stepper-circle {
  width: 13px !important;
  height: 13px !important;
}

#tranchestepper .step.active .bs-stepper-circle {
  /* width: 1.5em !important; */
  height: 0.5em !important;
}

/*
  #tranchestepper .step .bs-stepper-line{
    width: 11.5em;
  } */

#tranchestepper .bs-stepper-header {
  /* width: 43.5em; */
  max-width: 25.5em;
}

/* span {cursor: text;} */
.stepper-label {
  height: 25px;
  /* width: 46px; */
  color: #777777;
  font-family: "Roboto Slab" !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.stepper-label-bold {
  height: 25px;
  /* width: 46px; */
  color: #333333;
  font-family: "Roboto Slab" !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
}

.stepper-upload {
  width: 90%;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
}

.bs-stepper-header .step {
  width: 24% !important;
}


.funds-Label-heading {
  height: 20px;
  width: 107px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.mt--15 {

  margin-top: -15px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
/* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
    */
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E4E2E2;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E4E2E2;
}


.loader-position {
  position: unset !important;
}

.mw80 {
  max-width: 80%;
}

.progressOverviewValue {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.progressOverviewKey {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 5px;
}

.heightJobsBtn {
  justify-content: center !important;
  display: flex;
  height: 70px;
  align-items: center;
}
.container .row .col-md-12 a{
  white-space: normal; 
  word-wrap: break-word;
}
.container .row .col-md-12 span{
  white-space: normal; 
  word-wrap: break-word;
}
