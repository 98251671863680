.about-container::-webkit-scrollbar {
  display: none;
}
.cancel-main-heading{
  background-color: #25429C !important;
  
}
.modal_close_btn{
  position: absolute;
  top: -38px;
  right: -30px;
  border: none;
  background: none;
}
.close_Btn{
  color: #ff0505;
  border-radius: 50%;
  border: 1px solid white;
  padding: 0px 10px;
  font-size: 25px;
  background-color: white;
  font-weight: 700;
  cursor: pointer;
}
.modal-body-bgclr{
  background-color: #D2EAFF70 !important;
}
.list_tags{
  color: #25429C !important;
}
.about-heading {
  padding-top: 80px;
  padding-bottom: 92px;
  box-sizing: border-box;
  height: 214px;
  border-bottom: 1px solid #efeeee;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(238, 242, 255, 0.53) 100%);
}
.Cancellation_heading{
  text-transform: capitalize;
  color: white !important;
  text-decoration: none;
  font-weight: 700;
  font-family: "Roboto Slab";
  font-size: 24px !important;
}
.cancellation_label{
  color: #25429c;
    font-weight: 700;
    font-size: 14px;
}
.tranche-head1 {
  color: #506EB9;
  font-size: 1.7rem;
  font-family: Roboto Slab;
  letter-spacing: 0.7px;
  font-weight: 700;
}

.subsqnt .s-no {
  font-size: 1rem;
  font-weight: bolder;
  margin-right: 5px;
}

.subsqnt .section-head {
  font-size: 1rem;
  padding-left: 5px;
}

.fileDiv {
  width: 100%;
}

.required {
  color: #FF0000;
}

.subsqnt .required {
  color: #FF0000;
}

.section-bg {
  background-color: #D2EAFF;
  padding: 10px;
}
.pfmsBtns .btn-sisfs{
  font-size: 10px !important;
    padding: 1px 15px !important;
    border-radius: 0.3rem !important;
}
.bgColumn {
  height: inherit !important;
}

.sNo {
  font-weight: 600;
  font-size: 1rem;
}
.st-info{
  color: red;
   font-weight: bold;
    font-size: small;
    margin-left: 28px;
}
.sectionHead {
  font-size: 1rem;
}
.pfmsInput{
  align-items: end;
}
.regText{
  font-size: 14px;
  font-weight: 600;
  color: #506EB9;
  margin-left: 20px;
}
.otherUploads {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.alignContents {
  height: 100%;
  background-color: #F9A8A8 !important;
  border: 2px solid #FF0000;
  cursor: default !important;
}
.indicationAlign{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.indicationSection{
  display: flex;
  align-items: center;
  justify-content: end;
}
.indicationBox{
  width: 13px !important;
  height: 13px !important;
  border-radius: 50%;
  /* border: 1px solid gray; */
}
.indicationDot{
  border-radius: 50%;
}
.Rejectedbg{
  height: 100%;
  background-color: #F9A8A8 !important;
  cursor: default !important;
  /* border: 2px solid #FF0000; */
}
.Acceptedbg{
  height: 100%;
  background-color: #72F583 !important;
  cursor: default !important;
  /* border: 2px solid #1B8D2A; */
}
.Submittedbg{
  height: 100%;
  background-color: #D2EAFF !important;
  cursor: default !important;
  /* border: 2px solid #F2AF13; */
}
.Readbg{
  height: 100%;
  background-color: #A4B6FB !important;
  cursor: default !important;
  /* border: 2px solid #0B2B9F; */
}
.savedbg{
  height: 100%;
  background-color: #D2EAFF !important;
  cursor: default !important;
  /* border: 2px solid #0B2B9F; */
}
.resubmittedbg{
  height: 100%;
  background-color: #FCDC92 !important;
  cursor: default !important;
}
.Info-circle {
  background-color: yellow;
  color: black;
  padding: 0 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
}
.Info-circle2 {
  background-color: black;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
}
.Info-circle-incomplete {
  background-color: #FF0000;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: default;
  border-radius: 50%;
}
.commentsColumn{
  max-width: 100px;
  word-wrap: break-word;
  vertical-align: baseline;
}
.Info-circle-complete {
  background-color: green;
  color: white;
  padding: 0 6px;
  font-size: 12px;
  cursor: default;
  border-radius: 50%;
}
.fileSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: inherit;
  justify-content: space-between;
}

.acceptMark {
  font-size: 1.5rem;
  line-height: 0;
}

.rejectMark {
  font-size: 1.5rem;
  line-height: 0;
}

.rejectInput textarea {
  width: 100%;
}

.errorVal {
  border: 2px solid red;
}

.rejectInput textarea::placeholder {
  text-align: center;
  padding: 10px;
}

.acceptFile {
  font-size: 14px;
  color: green;
}
.acceptFile1 {
  font-size: 14px;
  color: green;
  font-weight: 700;
  cursor: default;
  margin-left: 10px;
}
.acceptFile:hover {
  text-decoration: green underline;
}
.rejectFile1 {
  font-size: 14px;
  color: red;
  font-weight: 700;
  cursor: default;
  margin-left: 10px;
}
.rejectFile {
  font-size: 14px;
  color: red;
}

.rejectFile:hover {
  text-decoration: red underline;
}

.Info-subhead {
  font-size: 1.3rem;
  font-weight: 600;
}
.Info-subhead1 {
  font-size: 1rem;
}
.commentsFile {
  font-size: 11px;
    background-color: #4670d994;
    color: white;
    padding: 5px 14px;
    border-radius: 15px;
    font-weight: 700;
}

.commentsFile2 {
  font-size: 8px;
  padding: 2px;
  background-color: #506EB9;
  border-radius: 50%;
  color: white;
  font-weight: 650;
}
.statusSection{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pointsList {
  word-break: break-all;
}

.pointlistTable th,
.pointlistTable td {
  border: 1px solid black !important;
  border-collapse: collapse;
  font-size: small;
}

.point-list .point {
  line-height: 2;
}

.susequentTable thead tr td {
  color: white;
  background-color: #506EB9;
  text-align: center;
  font-weight: 650;
}

.susequentTable tbody tr td {
  background-color: #D2EAFF70;
  text-align: center;
}

.fileUploadSec .filebox {
  background-color: white !important;
  border: dashed 2px lightgray;
}

.filebox1 {
  background-color: white !important;
  border: dashed 2px lightgray;
  border-top: none;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filebox1Sec {
  border-top: dashed 2px lightgray !important;
}
.uploadText {
  font-weight: 600;
}

.fileRemove {
  /* font-size: 1.5rem; */
  color: gray;
  cursor: pointer;
}

.alignContents-col {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-between; */
}

.removeIcon {
  font-size: large;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.alignContents-col {
  height: 100%;
}

.fileUploadSec label {
  display: inline-block;
  /* background-color: indigo; */
  /* color: white; */
  /* padding: 0.5rem; */
  /* font-family: sans-serif;
  border-radius: 0.3rem; */
  cursor: pointer;
  /* margin-top: 1rem; */
}

.filebox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-heading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 45px;
  margin-bottom: 1%;
  /* font-size: 38px; */
  font-size: 36px;
  text-align: left;
}

.about-content {
  height: auto;
}

.about-content {
  padding-top: 50px;
}

.about-list {
  float: center;
  /* padding-top: 74px; */
  /* padding-left:125px; */
  padding-left: 0px;
  list-style-type: none;
}

.fixed {
  top: 135px;
  position: fixed;
}

.about-list>li>a {
  cursor: pointer;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 45px;
}

.about-list>li>a.active {
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 45px;
  color: #333333;
  text-decoration: none;
}

.about-list>li>a {
  position: relative;
}

.about-list>li>a:hover {
  color: #e26640 !important;
}

.about-list>li>a.active:hover {
  color: #333333 !important;
}

.about-list>li>a.active::after {
  content: "";
  font-weight: 1000;
  color: #e26640;

  width: 8px;
  min-width: 8px;
  border-bottom: solid 2px #e26640;
  position: absolute;
  top: 0%;
  z-index: 1;
  right: -15px;
  height: 12px;
}

.right-cont {
  height: auto;
}

.right-heading {
  font-size: 28px;
  line-height: 47px;
}

.para-text {
  padding-top: 2%;
  padding-top: 2%;
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: justify;
}

.para-text-orange {
  padding-top: 2%;
  padding-top: 2%;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: justify;
}

.para-orange {
  padding-top: 1%;
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 0px;
}

.about-content .img-fluid {
  max-width: 80%;
  margin-bottom: 7%;
}

.about-right-img {
  padding: 3%;
}

.about-left-img {
  margin-top: 30%;
}

.left-cont {
  padding-left: 5% !important;
}

.right-cols {
  padding-bottom: 60px;
  padding-left: 1%;
  width: 100%;
}

.right-cols:last-child {
  padding-bottom: 0px;
}

.img-sqr {
  box-sizing: border-box;
  height: 150px;
  width: 150px;
  background-color: #d9e3ec;
  margin-bottom: 5%;
}

.img-members {
  min-width: 140px !important;
  height: 130px !important;
}

.img-sqr-text {
  color: rgba(0, 0, 0, 0.18);
  font-family: "Open Sans";
  font-size: 35px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
}

.img-div {
  margin-right: 3%;
}

.img-div-row {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 30px;
}

.card-text {
  color: #555555;
  font-family: "Open Sans";
  font-weight: 400;
  width: 140px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.about-container .card-heading {
  color: #333333;
  width: 140px;
  font-family: "Open Sans";
  font-weight: 600;
  margin-bottom: 5px !important;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.ordered-list ol {
  list-style: none;
  counter-reset: item;
}

.ordered-list li {
  counter-increment: item;
  margin-bottom: 25px;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;

  font-family: "Open Sans";
}

.about-ordered-list ol {
  padding-left: 50px;
}

.about-ordered-list li:before {
  margin-right: 20px !important;
  content: counter(item);
  background: #f8d874;
  border-radius: 100%;
  color: #25429c;
  width: 1.5em;
  text-align: center;
  display: inline-block;
  margin-left: -50px !important;
}

#myBtn {
  z-index: 99;
  font-size: 18px;
  cursor: pointer;
  color: #e26640;
  font-family: "Open Sans";
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
}

.range-container {
  position: relative;
  width: 80%;
  margin: 20px auto;
}

.range-slider {
  width: 100%;
}

.range-tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
}

.top-arrow {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  padding-right: 55px;
}

.objectives-nav {
  margin-bottom: 20px;
  margin-top: 20px;
}

.objectives-nav>ul.nav-tabs {
  list-style-type: none;
  padding-left: 0;
  border-bottom: none;
  display: block;
  margin-bottom: none;
  height: 38px;
  border-bottom: 1px solid #e0e0e0;
}

.objectives-nav>ul>li {
  display: inline;
  margin-right: 40px;
  line-height: 24px;
  padding-bottom: 12px;
}

.objectives-nav>ul>li>a {
  color: #555555;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
}

.objectives-nav>ul>li>a:hover {
  text-decoration: none;
  color: #333333;
  padding-bottom: 13px;
  line-height: 24px;
  cursor: pointer;
}

.objectives-nav>ul>li.active {
  display: inline;
  margin-right: 40px;
  line-height: 24px;
  /* border-bottom:4px solid #E26640; */
}

.objectives-nav>ul>li.active a {
  border-bottom: 4px solid #e26640;
  padding-bottom: 10px;
  line-height: 24px;
}

.objectives-nav>ul>li a.active {
  padding-bottom: 10px;
  line-height: 24px;
  border-bottom: 4px solid #e26640;
}

.objectives-nav>ul>li.active>a {
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
}

.faq-container {
  height: 238px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.faq-heading {
  margin-bottom: 75px;
}

.question-tab {
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.question-tab .para-text {
  padding-top: 3%;
}

.question-tab .ordered-list ol {
  padding-top: 20px;
}

.question-heading {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 31px;
}

.question-heading-orange {
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 20px;
  letter-spacing: 0;
  line-height: 31px;
}

.hide-icon {
  width: 30px;
  height: 30px;
  margin-top: 20px !important;
  cursor: pointer;
}

.expand-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 20px !important;
}

.help-content {
  padding: 3%;
  text-align: left;
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
}

#show {
  display: none;
}

.about-ordered-list li {
  line-height: 30px;
  font-size: 18px;
  color: #333333;
}

.faq-ordered-list {
  margin-top: 20px;
  margin-bottom: 20px;
}

.faq-ordered-list li {
  line-height: 30px;
  font-size: 18px;
}

table {
  font-family: "Open Sans";
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th {
  background-color: #fafafa;
  text-align: left;
  padding: 8px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  padding: 10px;
}

td {
  color: #333333;
  cursor: pointer;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  padding: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

tr:nth-child(even) {
  background-color: #fafbfc;
}

.tableDataHead {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}

.tableDataApplication {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.login-nav {
  margin-bottom: 20px;
  margin-top: 30px;
}

.login-nav>ul.nav-tabs {
  list-style-type: none;
  padding-left: 0;
  border-bottom: none;
  margin-bottom: none;
  height: 60px;
  border-bottom: 1px solid #e0e0e0;
}

.login-nav>ul>li {
  display: inline;
  line-height: 21px;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;
  width: 40%;
  padding-bottom: 12px;
}

.login-nav>ul>li>a {
  color: #555555;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
}

.login-nav>ul>li>a:hover {
  text-decoration: none;
  color: #333333;
  padding-bottom: 13px;
  line-height: 21px;
  cursor: pointer;
}

.login-nav>ul>li.active {
  display: inline;
  line-height: 21px;
  margin-left: 15px;
  text-align: center;
  width: 40%;
  margin-right: 15px;
  border-bottom: 4px solid #e26640 !important;
}

.login-nav>ul>li.active>a {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.incubatorCardDiv {
  margin-bottom: 15px !important;
}

/* STARTUP DETAIL DROPDOWN STYLE START */
.dropdown-statup-detail .dropdown-statup-detail-label {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  letter-spacing: 0;
  line-height: 20px;
  cursor: pointer;
  margin: 7px 0px 0px 20px;
}

.dropdown-statup-detail .fa-angle-down {
  color: #333;
  font-size: 22px;
  font-weight: 600;
}

.dropdown-statup-detail .dropdown-statup-detail-menu {
  width: 380px;
  padding: 20px 20px 5px 20px;
  z-index: 10000;
  box-shadow: 0 1px 5px 0 rgba(191, 196, 201, 0.84);
  border: none;
  margin-top: 10px;
}

.dropdown-statup-detail .dropdown-menu-list-header {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: -0.08px;
  line-height: 22px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.dropdown-statup-detail .dropdown-item-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22px;
}

.dropdown-statup-detail .dropdown-item-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  float: right;
  text-decoration: underline;
  margin: 2px 0;
}

/* STARTUP DETAIL DROPDOWN STYLE END */
/* DETAILS TABS STYLE START */
.details-tabs .nav-tabs .nav-link {
  padding: 0;
  border: none;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-right: 11px;
  margin-bottom: 0;
  position: relative;
  height: 100%;
  margin-bottom: 10px;
}

.details-tabs .nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.details-tabs .nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0px;
  margin-bottom: 20px;
}

.details-tabs .nav-tabs .nav-link.active {
  font-weight: 600;
}

.details-tabs .nav-tabs .nav-link.active::after {
  content: "";
  border-bottom: 4px solid #e26640;
  position: absolute;
  height: 4px;
  width: 100%;
  left: 0;
  bottom: 0;
  top: auto;
  right: auto;
}

.details-tabs .details-tabs-content .tab-pane-header {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  border-bottom: 1px solid #e0e0e0;
  line-height: 21px;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.details-tabs .details-tabs-content .tab-pane-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 5px;
}

.details-tabs .details-tabs-content .tab-pane-label-value {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.details-tabs .details-tabs-content .tab-pane-value-year-time {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.details-tabs .details-tabs-content .tab-pane-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.details-tabs .details-tabs-content .tab-pane-label-feedback {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.cancellation-comment-tab .tab-pane-label-feedback {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.cancellation-comment-tab .tab-pane-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.cancellation-comment-tab .tab-pane-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.details-tabs .details-tabs-content .tab-pane-label-group {
  margin-bottom: 20px;
}

.details-tabs .details-tabs-content .tab-pane-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}

.details-tabs .details-tabs-content hr {
  border-top: 1px solid #e0e0e0;
  margin-top: 0px;
}

.details-tabs .details-tabs-content .tab-pane-label-small {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
}

.details-tabs .details-tabs-content .tab-pane-value-highlight {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 25px;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-count {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 25px;
  letter-spacing: 0;
  line-height: 34px;
}

.details-tabs .details-tabs-content .tab-pane-total {
  margin-top: 20px;
}

.details-tabs .details-tabs-content .tab-pane-total.tab-pane-total-spacing {
  border-right: 1px solid #e0e0e0;
  margin-right: 25px;
  padding-right: 25px;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-count span {
  font-size: 38px;
  font-weight: 600;
}

.details-tabs .details-tabs-content .tab-pane-total .tab-pane-total-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

/* DETAILS TABS STYLE END */
/* DETAILS ACCORDION STYLE START */
.accordion-details-wrap .accordion-card {
  border-radius: 0;
  background: transparent;
  border: none;
  border-bottom: 1px solid #e0e0e0 ;
}

.newIcon { 
  font-size: 5em; 
  font-family: serif; 
  color: #008000; 
  text-align: center; 
  animation: animate 0.8s ease-in-out infinite; 
} 

@keyframes animate { 
  0% { 
      opacity: 0; 
      transform: scale(0.9); 
  } 

  50% { 
      opacity: 0.7; 
      transform: scale(1.1); 
  } 

  100% { 
      opacity: 0; 
      transform: scale(0.9); 
  } 
} 

.accordion-details-wrap .accordion-card .accordion-card-header {
  background: transparent;
  border: none;
  margin: 0;
  padding: 15px 0;
  color: #e26640;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  cursor: pointer;
}

.accordion-details-wrap .accordion-card .accordion-card-header span {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 15px;
}

.accordion-details-wrap .accordion-card .accordion-card-header:after {
  content: "";
  background-image: url(../images/Minus.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 21px 21px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-header.collapsed,
.accordion-details-wrap .accordion-card .accordion-card-header.collapsed span {
  color: #333;
}

.accordion-details-wrap .accordion-card .accordion-card-header.collapsed:after {
  content: "";
  background-image: url(../images/Plus.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  background-size: 21px 21px;
  width: 21px;
  height: 21px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body {
  padding: 10px 30px 15px 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group {
  margin-bottom: 25px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
  text-align: left;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label-highlight {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-label-big {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 5px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-icon {
  margin-right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-info-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-pdf-icon::after {
  content: "";
  background-image: url(../images/PDF.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 46px;
  height: 45px;
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-xls-icon::after {
  content: "";
  background-image: url(../images/PDF.svg);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 46px;
  height: 45px;
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-info {
  position: relative;
  margin-top: 20px;
  padding-left: 55px;
  min-height: 48px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-list {
  padding-left: 20px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .startup-info-group-value-document .document-img {
  margin-right: 10px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .document-name {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.accordion-details-wrap .accordion-card .accordion-card-body .tab-pane-link-view {
  text-decoration: none;
}

/* DETAILS ACCORDION STYLE END */

/*PORTFOLIO CSS START*/
.portMainHeading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 35px;
  margin-bottom: 15px;
  font-size: 30px;
  text-align: left;
  font-weight: 400;
}

.portFoundText {
  font-weight: 600;
  font-size: 18px;
}

.portFolioGrid {
  border: 1px solid #e7e7e7;
  margin-top: 25px;
}

.portContentBlock {
  padding: 10px 15px;
}

.portCity {
  margin-bottom: 5px;
  font-size: 13px;
}

.portImage {
  width: 100%;
  /* min-height: 185px; */
  height: 185px;
}

.portLogo {
  height: 75px;
  object-fit: contain;
}

.portImageMain {
  width: 100%;
  object-fit: contain;
}

.portHeading {
  color: #25429c;
  font-family: "Roboto Slab";
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 15px;
  font-size: 18px;
  text-align: left;
  font-weight: 400;
  min-height: 48px;
  max-height: 48px;
}

.portDescription {
  margin-bottom: 15px;
}

.portTag {
  display: contents;
}

.portTagDetail span {
  border: 1px solid #e7e7e7;
  padding: 0px 15px;
  border-radius: 50px;
  font-size: 13px;
  line-height: 32px;
  margin-bottom: 10px;

}

.portTag span {
  border: 1px solid #e7e7e7;
  padding: 5px 15px;
  border-radius: 50px;
  font-size: 13px;
  line-height: 32px;
  margin-bottom: 10px;
}

.twoLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.portLink {
  color: initial;
  text-decoration: none;
}

.portLink:hover {
  color: initial;
  text-decoration: none;
}

.portDetailHeading {
  font-weight: 500;
  font-size: 28px;
}

/*PORTFOLIO CSS END*/

/* tooltip css */
[data-md-tooltip] {
  position: relative;
  cursor: pointer;
}

[data-md-tooltip]:before {
  content: attr(data-md-tooltip);
  position: absolute;
  bottom: -35px;
  left: 50%;
  padding: 8px;
  transform: translateX(-50%) scale(0);
  transition: transform 0.3s ease-in-out;
  transform-origin: top;
  background: #616161e6;
  color: white;
  border-radius: 2px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

[data-md-tooltip]:hover:before {
  transform: translateX(-50%) scale(1);
}

[data-md-tooltip].md-tooltip--right:before {
  left: auto;
  right: -10px;
  bottom: auto;
  top: 50%;
  transform: translate(100%, -50%) scale(0);
  transform-origin: left center;
}

[data-md-tooltip].md-tooltip--right:hover:before {
  transform: translate(100%, -50%) scale(1);
}

[data-md-tooltip].md-tooltip--left:before {
  left: -15px;
  right: auto;
  bottom: auto;
  top: 50%;
  transform: translate(-100%, -50%) scale(0);
  transform-origin: right center;
}

[data-md-tooltip].md-tooltip--left:hover:before {
  transform: translate(-100%, -50%) scale(1);
}

.md-tooltip--top:before {
  top: -85px;
  bottom: auto;
  transform: translateX(-50%) scale(0);
  transform-origin: bottom center;
  z-index: 9999;
}

/* tooltip css End*/
.bs-stepper-header a {
  color: #25429C !important;
}