.incubators-name-heading {
  height: 30px;
  width: 248px;
  color: #a36363;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;

}

.incubators-title {
  font-family: "Open Sans";
  color: #E26640;
  height: 30px;
  /* width: 248px; */
  font-size: 14px;
  letter-spacing: 0;
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.incubator-rectangle {
  height: auto;
  width: 300px;
  background-color: #F8F7F7;
  padding-bottom: 10px;
}

.incubator-rectangle-2 {
  /* height: 267px; */
  /* width: 300px; */
  background-color: #F8F7F7;
}

.incubator-label {
  height: 17px;
  /* width: 127.1px; */
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap
}


.incubator-label-heading {

  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 16px;
  font-weight: bold;
}
.seperator{
  /* width: 25vw; */
  /* margin-left: 0; */
  border-bottom: 1px solid #dcdedf;
}
.incubator-label-wrap {
  height: 17px;
  width: 127.1px;
  font-weight: bold;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 15;
  line-height: 17px;
}

.incubator-label-value {
  height: 20px;
  width: 115px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  white-space: inherit;
}
.incubator-label-value input[type="text"]{
  /* background: transparent !important; */
    border: none !important;
}
.incubator-email-value {
  height: 30px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  font-weight: 600;
  line-height: 30px;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

.incubator-name-value {
  height: 20px;
  width: 115px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;

  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap
}

.box-overflow {
  min-height: 205px;
  width: 280px;
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 1px rgba(157, 162, 166, 0.61);
  overflow: auto;
}

.ul-b {
  list-style-type: circle;

}


.ul-ml {
  margin-left: -1.5em;
}


.incubator-label-value-wrap {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  word-break: break-all;
}

.website-link {
  height: 20px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
}

.document-label-value-wrap {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  word-break: break-word;
}

.document-label-value {
  height: 20px;
  /* width: 115px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  /* margin-left:15px; */
}

.incubator-name {
  height: 27px;
  /* width: 190px; */
  color: #2B408B;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 0px;
}

.eac-name-pop-up {
  height: 28px;
  /* width: 218px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}

.tab-header {
  height: 24px;
  width: 81px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 24px;
}

.incubator-hr {
  /* height: 15px; */
  margin-left: 0px;
  margin-right: 10px;
}


.line-height-24 {
  line-height: 24px;
}

.group-10 {
  height: 168px;
  width: 256px;
}

/* .group-11 {
  width: 200px;
} */

.eac-heading-title {
  height: 21px;
  width: 109px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
}

.left-align {
  text-align: left;
}

.mt-3-px {
  margin-top: 3px !important;
}

.ml-21-px {
  margin-left: 21px !important;
}

.mt-15-px {
  margin-top: 15px !important;
}



.ml-18px {
  margin-left: 18px;
}

.ml-20px {
  margin-left: 20px;
}

.mb-1rem {
  margin-bottom: 1rem !important;
}


.modal-header-border-none {
  border-bottom: none !important;
}

.center-align {
  text-align: center;
}

.meeting-heading-subtitle {
  height: 21px;
  width: 109px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}

.div-disable {
  pointer-events: none;
}

/* .eac-review-subtitle {
  height: 24px;
  width: 97px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 33px;
  text-align: left;
} */

.eac-incubator-hr {
  height: 15px;
  margin-left: 0px;
  margin-right: 0px;
}

.application-incubator-hr {
  margin-left: 0px;
  margin-right: 0px;
}

.eac-review-title {
  height: 20px;
  width: 255px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}

.eac-review-subtitle {
  height: 17px;
  width: 161px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
}

.eac-p {
  height: 63px;
  width: 427px;
  color: #777777;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.incubator-details-link {
  height: 30px;
  width: 81px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
}

.incubator-details-link:hover {
  color: #E26640;
}

.incubators-title:hover {
  color: #E26640;
}

.incubator-email-value:hover {
  color: #E26640;
}

.incubator-link-number:hover {

  text-decoration: none;

}

.eac-rectangle {
  height: 90px;
  width: 139px;
  border-radius: 7px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 #CCD1E0;
}

.incubator-link-number {
  height: 20px;
  width: 22px;
  color: #25429C;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
}

.mt-05 {
  margin-top: 0.5rem;
}


.mt-1-5 {
  margin-top: 1.5rem;
}



.ml-1-5 {
  margin-left: 1.5rem;
}

.mr-1-5 {
  margin-right: 1.5rem;
}

.vl-mr {
  margin-right: 10px;
  margin-left: 10px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}


.margin-16 {
  margin: 16px;
}

.margin-20 {
  margin: 20px;
}

.margin-35 {
  margin: 35px;
}


.margin-26 {
  margin: 26px;
}


.margin-left-20 {
  margin-left: 20px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-3 {
  margin: 3px;
}

.top-margin-10 {
  margin-top: 10px;
}


.margin-bottom-24 {
  margin-bottom: 24px;
}


.margin-bottom-10 {
  margin-bottom: 10px;
}


.eac-member {
  height: 15px;
  width: 67px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  white-space: nowrap;
}

.eac-member-name {
  height: 20px;
  width: 54px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 600;
  white-space: nowrap;
}

.label-lh-28 {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap
}

.label-lh-28-wrap {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;

}


.label-lh-30 {
  height: 17px;
  width: 127.1px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  white-space: nowrap
}

.value-lh-20 {
  height: 120px;
  width: 202px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  white-space: wrap !important;
  word-break: break-all;
}

.reports-heading {
  height: 21px;
  width: 125px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.fund-amount-label-value {
  /* height: 52px; */
  color: #25429C;
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 29px;
  font-weight: 600;
  margin-right: 10px;
}


.fund-amount-cur {
  height: 52px;

  color: #25429C;
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 29px;

}

.fund-amount-label {
  height: 22px;
  width: 141px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  line-height: 22px;
  white-space: nowrap;
}
.fund-amount-label-new{
  height: 22px;
  /* width: 141px; */
  color: #333333;
  font-family: "Open Sans";
  font-size: 10px;
  letter-spacing: 0;
  /* line-height: 22px; */
  /* white-space: nowrap; */
}
.vl {
  border-left: 1px solid #E0E0E0;
  height: 102px;
}

.mb-12 {
  margin-bottom: 12px;
}


.mt-7 {
  margin-top: 7px;
}

.tranche-heading {
  height: 21px;
  width: 72px;
  color: #E26640;
  font-family: "Roboto Slab";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
}

.tranche-subheading {
  height: 20px;
  width: 274px;
  color: #E26640;
  font-family: "Roboto Slab";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}


.reports-subheading {
  height: 20px;
  width: 274px;
  color: #25429C;
  font-family: "Roboto Slab";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}


.fund-label {
  height: 40px;
  width: 290px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}

.tranch-details-heading {
  height: 47px;
  width: 195px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 47px;
  white-space: nowrap;
}

.tranche-details-box {
  height: 592px;
  width: 775px !important;
}

.tranche-label {
  height: 22px;
  width: 141px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
}

.tranche-link {
  height: 19px;
  width: 140px;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: right;
}

.tranche-label-bold {
  height: 34px;
  width: 101px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
}

.tranche-label-normal {
  height: 17px;
  width: 69px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.tranche-row-header-grey {
  height: 68px;
  width: 720px;
  background-color: #F8F7F7;
}

.tranche-row-grey {
  height: 69px;
  width: 734px;
  background-color: #FAFBFC;
}


.tranche-row {
  height: 69px;
  width: 734px;
}



.tranche-label {
  height: 17px;
  width: 69px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  white-space: nowrap
}

.tranche-stepper-button {
  border: 0px;
  background: none;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
} */

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 0%;
  margin-left: -50px;

  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


.tooltip-overwrite {
  opacity: 1 !important;
  /* position: unset !important; */
  margin: 4px !important;
}

.visible-hide {
  visibility: hidden;
}

.general-label-value {
  width: 202px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;


}

.general-label {
  width: 265px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
}


.general-label-heading {

  color: #555555;
  font-family: "Open Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  font-weight: bold;
}


.general-label-nowrap {
  width: 265px;
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  white-space: nowrap;
}


.this.state.incubatorDetailsModel.submitted_at {
  width: 265px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 16px;
  white-space: nowrap;
  font-weight: bold;
}

.nav-link.active incubator {
  border-bottom: 4px solid #e26640;
  color: rgb(85, 85, 85);
}


.nav-incubator {
  height: 24px;
  /* width: 81px; */
  color: #555555;
  font-family: "Open Sans" !important;
  font-size: 15px !important;
  letter-spacing: 0;
  line-height: 0px !important;
}


.hide-icon-details {
  margin-top: 0px !important;
}

.expand-icon-details {
  margin-top: 0px !important;
}

.chart-nav>li>.nav-incubator.active {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 0px !important;
  border-color: white;
}


.incubator-address {
  height: 28px;
  width: 125px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 22px;
}

.chart-nav>li>.nav-incubator {
  height: 42px !important;
}

.chart-nav>li>.nav-incubator.active {
  border-bottom: 4px solid #e26640 !important;
  border: 0px;
}

.chart-nav .nav-item {
  padding: .0px 1rem;
}

.chart-nav .nav-link {
  padding: .5rem 0px;
}

.chart-nav>li>.nav-incubator:hover {
  border-bottom: 4px solid transparent;
}

.margin-top-minus-17 {
  margin-top: -17px;
}


.margin-top-1 {
  margin-top: 1px !important;

}


.padding-top-12 {
  padding-top: 12px !important;

}

.group-3 {
  height: 106px;
  width: 142px;
}

#tranchestepper .step .bs-stepper-circle {
  width: 13px !important;
  height: 13px !important;
}

#tranchestepper .step.active .bs-stepper-circle {
  /* width: 1.5em !important; */
  height: 0.5em !important;
}

/*
  #tranchestepper .step .bs-stepper-line{
    width: 11.5em;
  } */

#tranchestepper .bs-stepper-header {
  /* width: 43.5em; */
  max-width: 25.5em;
}

/* span {cursor: text;} */
.stepper-label {
  height: 25px;
  /* width: 46px; */
  color: #777777;
  font-family: "Roboto Slab" !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.stepper-label-bold {
  height: 25px;
  /* width: 46px; */
  color: #333333;
  font-family: "Roboto Slab" !important;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
}

.stepper-upload {
  width: 90%;
  color: #E26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  text-decoration: underline;
  cursor: pointer;
}

.bs-stepper-header .step {
  width: 24% !important;
}


.funds-Label-heading {
  height: 20px;
  width: 107px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.mt--15 {

  margin-top: -15px;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
/* ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
    */
/* Handle */
::-webkit-scrollbar-thumb {
  background: #E4E2E2;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #E4E2E2;
}


.loader-position {
  position: unset !important;
}

.mw80 {
  max-width: 80%;
}

.progressOverviewValue {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 30px;
}

.progressOverviewKey {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 5px;
}

.heightJobsBtn {
  justify-content: center !important;
  display: flex;
  height: 70px;
  align-items: center;
}
.container .row .col-md-12 a{
  white-space: normal; 
  word-wrap: break-word;
}
.container .row .col-md-12 span{
  white-space: normal; 
  word-wrap: break-word;
}