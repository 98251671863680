.dashboard {
  height: 20px;
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.dashboard_inc {
  /* height: 20px; */
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 20px; */
}
.dashboard_Application {

  height: 20px;

  color: #333333;

  font-family: "Roboto Slab";

  font-size: 22px;

  font-weight: 600;

  letter-spacing: 0;

}
.itemsPerPage{
  color: #25429c;
  font-size: 15px !important;
}
.itemsPerPageSelect{
  border: none;
    border-bottom: 3px solid #dc3545;
    color: #25429c;
    font-weight: 600;
    font-size: 15px;
}
.pagination a:not([href]):not([class]){
  display: none !important;
  font-size: 18px !important;
}
.pageNum{
  font-size: 15px;
  color: #25429c;
  margin-bottom: 3px;
}
.pagination [role=button]{
  font-size: 15px !important;
  font-weight: 600;
  padding: 3px !important;
  margin-right: 1px !important;
  color: #25429c !important;
  /* background-color: #f8d874; */
}
.group-2 {
  height: 163px;
  width: 940px;
}
.full-width {
  width: 100% !important;
}
.rectangle-1 {
  height: 163px;
  width: 210px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 10px 0px;
  text-align: center;
}
.rectangle-2 {
  height: 130px;
  width: 190px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 10px 0px;
  text-align: center;
}
.rectangle-incubator {
  height: 163px;
  width: 290px;
  border-radius: 20px;
  background-color: #f7f8ff;
  /* margin: 10px; */
  margin: 10px 0px;
  text-align: center;
}

.rectangle-card-selected-orange {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #ffa50080;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.rectangle-card-selected-green {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #68e56880;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-selected {
  height: 113px;
  width: 104px;
  border-radius: 20px;
  background-color: #f7f8ff;
  margin: 6px;
  padding: 1px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-selected-bottomBox {
  height: 55px;
  width: 300px;
  border-radius: 10px;
  background-color: #f8d874;
  margin: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 4px;
  box-shadow: 5px 5px 10px #808080a1;
}

.rectangle-card {
  height: 113px;
  width: 106px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}

.rectangle-card-bottom {
  height: 55px;
  width: 300px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  text-align: center;
  cursor: pointer;
  padding-top: 4px;
}

.rectangle-card:hover, .rectangle-card-bottom:hover {
  border: 1px solid #25429c;
}

.incubator-numeric {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 70px;
  cursor: default;
}
.smallIncubatorNumeric {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: justify;
}

.smallIncubatorNumeric_bottom_bukket {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: justify;
}

.blue-arrow {
  margin-left: 5px;
}
.incubator-text-selected-small {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.incubator-text-selected-small-buttom {
  color: #333333;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-right: 40px;
}

.incubator-numeric-black {
  color: #999999;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}

.incubator-numeric-black-buttom-bucket {
  color: #999999;
  font-family: "Open Sans";
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}
.reviewed-count{
  line-height: 38px !important;
}
.reviewed-text{
  font-size: 11px !important;
}

.rectangle-card:hover .incubator-numeric-black,
.rectangle-card:hover .card-text-incubator,
.rectangle-card:hover .card-text-incubator-buttom,
.rectangle-card:hover .incubator-numeric-black-buttom-bucket {
  color: #25429c;
}
.tool-tip{
  position: relative;
}
.tool-tip::after {
  content: "Startups puts in this bucket will not stop the 45 days period of evaluation from expiring.";
  position: absolute;
  opacity: 0;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.fileToolTip .tool-tip{
  position: relative;
}
.fileToolTip .tool-tip::after {
  content: ".png/.jpg/.jpeg images only";
  position: absolute;
  opacity: 0;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.fileToolTip .tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.emailToolTip .tool-tip{
  position: relative;
}
.emailToolTip .tool-tip::after {
  content: "This email ID will be published on the Portfolio Page of the SISFS website,which will be publicly visible to all visitors of the incubator’s page";
  position: absolute;
  opacity: 0;
  width: fit-content;
  font-size: 14px;
  white-space: nowrap;
  visibility: hidden; 
  pointer-events: none;
  background-color: #000000; 
  color: #fff;
  transition: opacity .3s;
  padding: 5px;
  z-index: 10;
  top: -10; 
  left: 100%; 
  transform: translateX(-50%,-110%);
  border-radius: 5px;
}
.emailToolTip .tool-tip:hover::after{
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  background-color: #000000;
}
.card-text-incubator {
  color: #999999;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
}

.card-text-incubator-buttom {
  color: #999999;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
  margin-right: 40px;
}

.box {
  width: 220px;
  height: 163px;
  margin-left: 10px;
}
.footer-2 {
  height: 71px;
  width: 1280px;
}

.rectangle-copy-3 {
  height: 71px;
  background-color: #fdfbfb;
}

.dashboard-footer-left {
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  padding-left: 3%;
  text-align: left;
}
.fa-calendar {
  color: #25429c;
}
.group-17 {
  height: 16px;
  width: 319px;
}

.footer-text {
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  text-align: right;
}
.modal-title {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 28px;
  letter-spacing: 0;
  line-height: 47px;
}
.modal-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
}
.view-button {
  height: 17px;
  width: 90px;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
  cursor: pointer;
  text-decoration: underline;
}
.view-button:hover {
  color: #e26640;
}

div.hr {
  height: 15px;
  background: #fff no-repeat scroll center;
  /* margin-left: 16px;
  margin-right: 16px; */
  margin-top: -15px;
}

.group {
  height: 57px;
  width: 58px;
}

.cardDiv {
  margin-bottom: 30px;
}

.chartDuration {
  /* height: 17px;
  width: 19px; */
  cursor: pointer;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-decoration: none;
  margin: 10px 4px;
  padding: 6px;
  min-width: 30px;
  display: inline-block;
}
.chartDurationTill:hover,
.chartDurationTill.active {
  background-color: transparent !important;
  text-decoration: underline;
  color: #e26640;
}

.chartDuration:hover {
  /* height: 50px;
  width: 50px; */
  background-color: #f8d874;
  border-radius: 50%;
}
.chartDuration.active {
  /* height: 50px;
  width: 50px; */
  background-color: #f8d874;
  border-radius: 50%;
}
.dashboard-heading-secion .chartDuration {
  margin: 5px 7px;
  padding: 8px 6px;
  min-width: 34px;
  display: inline-block;
}
.calendar-icon {
  margin: 0px 5px;
  border-left: 1px solid #dddddd;
  padding-left: 15px;
  cursor: pointer;
}

.scheduleMeeting {
  height: 450px;
  /* width: 300px !important; */
  border-radius: 20px;
  background-color: rgba(248, 216, 116, 0.25);
  box-sizing: initial;
  margin-left: 14px;
}

.upcoming-meeting {
  height: 21px;
  width: 147px;
  color: #25429c;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 50px;
}
.lineChartDiv {
  margin: 0 !important;
}
.modal-header-bottom {
  border-bottom: 0px !important;
  padding-right: 65px !important;
}
.input-height {
  height: 46px !important;
}
.view-all {
  height: 17px;
  width: 47px;
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 50px;
  text-decoration: underline;
  cursor: pointer;
}
.view-all:hover {
  color: #e26640;
  text-decoration: underline;
}
.pending-count-text {
  color: #777777;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  margin-top: 5px;
  margin-bottom: 0;
}
.tertiary {
  width: 177.17px;
  color: #25429c;
  font-family: "Open Sans" !important ;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  border-radius: 20px !important;
  padding: 10px;
}

.centerText {
  text-align: center;
}

.eac-performance-revi {
  height: 38px;
  width: 154px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 19px;
}

.eacMeetTime {
  height: 21px;
  width: 144px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.eacDateNumber {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 52px;
  margin-right: 6px;
}

.eacDateMonth {
  height: 21px;
  width: 46px;
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}
/* .hori-bar.chartjs-size-monitor.chartjs-render-monitor{
  height: 250px !important;
  padding-right: 8px !important;
  padding-left: 8px !important;
} */

.rectangle {
  /* height: 400px; */
  margin-top: 10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #ccd1e0;
  padding-top: 12px;
}
.duration-bottom {
  border-bottom: 0.5px solid #c9c9c9;
  text-align: center;
  padding-bottom: 5px;
}
.rectangle-incubator-bar {
  height: 510px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 8px 0 #ccd1e0;
}
.review-calendar-icon {
  margin: 0px;
}
.incubator-applicatio {
  color: #333333;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
}

.burns-industries {
  height: 20px;
  width: 122.02px;
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.card-count {
  color: #25429c;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: bold;
}
.count-bg {
  font-size: 38px !important;
  font-weight: 600 !important;
}

.mumbai-maharashtra {
  height: 17px;
  width: 127.1px;
  color: #777777;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}

.eac-review {
  height: 100%;
  width: 35%;
}
.eac-review-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #f5f5f5; */
  height: 100%;
  position: relative;
}
.eac-review-wrapper .eac-review-heading {
  font-size: 30px;
  color: #2b408b;
}
.eac-review-wrapper .close {
  position: absolute;
  top: 0px;
  right: 20%;
  font-size: 42px;
  font-weight: 500;
}
.eac-review-wrapper .form-control {
  padding: 20px 0.75rem;
  color: #777777;
}
.eac-review-wrapper .btn-sisfs {
  padding: 10px 20px !important;
}

.eac-review-meeting {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.chart-nav > li > .nav-link {
  color: #999999;
  font-family: "Roboto Slab";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}
.chart-nav > li > .nav-link.active {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  border-color: white;
}
.nav-tabs.chart-nav {
  border-bottom: 0;
}
.vertical-dash {
  width: 2px;
  height: 18px;
  margin-top: 12px;
  background-color: #999999;
}
.tab-content {
  margin-top: 21px;
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  transform: translate(125%, 0px);
  width: 500px;
  height: 100%;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 0;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 500px;
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
}
.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

#exampleModal1.modal.fade.show .modal-dialog.modal-dialog-slideout {
  width: 500px !important;
}
.searchBar {
  padding: 10px 10px 10px 30px;
  margin-left: -25px;
  width: 330px;
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  background-color: #fcfcfc;
}
.searchBar:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.search {
  z-index: 1;
}
.searchDiv {
  margin-right: 0;
  padding-left: 30px;
}
.count-text-incubator {
  color: #333333;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 30px;
}
.incubator-heading-div {
  margin-bottom: -2px;
  flex-wrap: nowrap !important;
}
.incubator-heading-review {
  margin-bottom: 20px;
}
table th,
table td {
  /* Use CSS variables to calculate equal width for n columns */
  width: 200px;
  /* Add other styling if needed */
}
.table-head {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
}
.count-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px;
}
.col-form-head {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22px;
}
.table-heading {
  color: #333333;
  padding: 25px 10px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 2;
}
.tableDataHead {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}
.tableDataApplication {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.badge-red {
  height: 23.45px;
  padding: 0 0 5px 5px;
  width: 100%;
  border-radius: 13.5px;
  background-color: #ffbfaa;
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}
.badge-grey {
  height: 24px;
  padding: 0 0 5px 5px;
  border-radius: 13.5px;
  background-color: #c4d2f4;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
}
.badgeGrey .badge-grey{
  height: 24px;
  padding: 0 0 5px 5px;
  border-radius: 13.5px;
  background-color: #c4d2f4;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  width: 200px;
  margin: auto;
}
.checkbox-text {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  padding-top: 4px;
  letter-spacing: 0;
  line-height: 24px;
}
.back-slash::before {
  content: "/";
  color: #555555;
}
.search-left {
  padding-left: 105px !important;
}
.search-left-meeting {
  padding-left: 0 !important;
}
.custom-td {
  /* min-width: 180px !important; */
  padding-left: 10px;
  word-wrap: break-word;
}
.custom-td-small {
  min-width: 120px !important;
  padding-left: 10px;
}

.Before_FiveDays {
  min-width: 120px !important;
  padding: 6px;
  border-radius: 6px;
  /* margin-right: 12px; */
  background-color: orange;
}

.custom-tdd-small {
  min-width: 140px !important;
  /* padding-left: px; */
}
.custom-td-left {
  min-width: 180px !important;
  padding-left: 18px;
}
.filter-icon {
  padding-left: 0px !important;
}
.filter {
  cursor: pointer;
}
.table-scroll {
  height: 500px;
  overflow: auto;
  width: 100%;
}
.table-scroll::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
}
.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
.radio-popup {
  margin-bottom: 20px;
}
.orange-link {
  color: #ff8962;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
@media (min-width: 576px) {
  .modal-dialog {
    /* max-width: 700px !important; */
    margin: 1.75rem auto;
  }
}
.radio-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 2%;
}
.dahboard-card-heading {
  color: #333333;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 21px;
  padding-top: 14px;
  margin-bottom: 0px;
  padding-left: 25px;
}
.link-header {
  padding: 0 !important;
  height: 40px;
}
.top-heading-dash {
  padding-bottom: 15px;
}
.message-orange {
  color: #e26640 !important;
}
.textarea-height {
  height: 117px !important;
}
.edit-column {
  cursor: pointer;
}
.btn-yellow-disabled {
  opacity: 0.6;
}
.custom-td-right {
  padding-right: 37px !important;
}
#filterModal .ms-wrapper .form-select {
  height: 46px !important;
  line-height: 2;
}
#exampleModalCenter .modal-body {
  padding: 20px 35px !important;
}
#exampleModalCenter .modal-header {
  padding: 15px 35px !important;
}
#exampleModalCenter .report-list {
  padding: 15px 0px;
  min-height: 75px;
  border-top: 1px solid #e3e3e3;
}
#exampleModalCenter .report-list:first-child{
  border-top: none;
}
#exampleModalCenter .report-title {
  border-bottom: 2px solid #999999;
}
.reviewTable {
  margin-top: 0 !important;
}
.reviewTable .btn-sisfs {
  padding: 0.275rem 1.5rem !important;
}
.filter-icon mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: -5px;
  left: 24px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.filter-icon1 mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: 5px;
  left: 50px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.filter-icon2 mark {
  border-radius: 20px;
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  background-color: #ff6b6b;
  position: absolute;
  top: 5px;
  left: 39px;
  font-size: 10px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  font-weight: 700;
}
.modal-row {
  display: contents !important;
}
.custom-td-last {
  padding-left: 0px !important;
}
.custom-td-last-head {
  padding-left: 0px !important;
  min-width: 180px !important;
}
.custom-td-width {
  min-width: 180px !important;
  padding-left: 10px;
}
#dashboard_line_chart canvas {
  /* height: 340px !important; */
  height: 100% !important;
}
#hori-bar canvas {
  height: 250px !important;
}
.count-small {
  font-size: 24px !important;
}
.error-wrapper {
  min-height: 400px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
}
.error-text {
  margin: auto;
  font-size: 50px;
  color: #e26640;
}
.upcomingMeetingClick {
  cursor: pointer;
}
.list-dashboard ul {
  list-style-type: none;
  padding-left: 0;
  border-top: 1px solid #e3e3e3;
}
.list-dashboard li {
  padding: 10px 10px;
}
.list-scroll {
  height: 200px;
  overflow: auto;
  width: 100%;
}
.schedule-button-pd {
  padding: 0 !important;
}
.dashboardContainer {
  padding-bottom: 40px !important;
}
#customTable th:last-child {
  min-width: 180px !important;
}
.searchUser {
  width: 100% !important;
}
.grievanceTable .btn-sisfs {
  padding: 0.275rem 15px !important;
}
.breadcrumb-wrapper .breadcrumb {
  background-color: transparent;
  padding-left: 0 !important;
  margin-bottom: 0px;
}
.breadcrumb-wrapper .breadcrumb li.active {
  color: #333333;
  font-weight: 600;
}
.grievance-chat-wrapper{
  padding:20px 0px;
  position: static;
}
.breadcrumb-wrapper .breadcrumb li,
.breadcrumb-wrapper .breadcrumb li a,
.breadcrumb-wrapper .breadcrumb li a:hover {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: none;
}
.grey-box {
  background-color: #f8f7f7;
  padding: 20px 20px;
  margin-bottom: 5px;
}
.grey-box .box-heading .box-main-heading {
  color: #2b408b;
  font-family: "Roboto Slab", serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
}
.grey-box .box-heading .box-main-subheading {
  color: #333333;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 28px;
}
.grey-box .box-heading {
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
}
.grey-box .box-content .content-list {
  padding: 10px 0px;
  word-break: break-all;
}
.grey-box .box-content {
  padding: 10px 0px 0px;
}
.grey-box .box-content .content-list .label-heading {
  color: #555555;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 17px;
}
.grey-box .box-content .content-list .content-value {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
}
.grey-box .box-content .content-list .content-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}
.grey-box .box-content .content-list .status-badge {
  width: 100% !important;
}
.grey-box .box-content .content-list .status-badge b{
  text-transform: capitalize;
}
.grey-box .box-content .content-list .document-label {
  color: #333333;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  overflow-wrap: anywhere;
}
.grievance-chat-wrapper .chat-heading .grievance-head {
  color: #333333;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
}
.grievance-chat-wrapper .chat-heading {
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0px 20px;
}
.chat-content .media-chat {
  text-align: right;
  width: 75%;
  margin-left: auto;
}
.chat-content .media-chat .media-body {
  background-color: #f7f8ff;
  padding: 15px 15px;
}
.chat-content .media-chat-reverse {
  text-align: left;
  width: 100%;
  margin-right: auto;
}
.chat-content .media-chat-reverse .media-body {
  background-color: transparent;
  border: 1px solid #dddddd;
  padding: 15px 15px;
}
.chat-content .chat-list { 
  padding: 15px 0px;
}
#chat-content{
  overflow-y: scroll !important;
  height:70vh !important;
  overflow-x: hidden;
  padding:0px 15px;
}
.chat-action .content-link {
  color: #e26640;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-decoration: none;
}
.chat-action{
  padding:20px 0px;
  border-top:1px solid #e0e0e0;
  position: sticky;
  bottom: 0px;
  background-color: #fff;
}
.grievanceTable thead th:first-child{
  width: 25%;
}
.grievanceTable thead th:last-child{
  width: 15%;
}
.grievanceTable tbody td:last-child{
  padding-left: 5px;
  padding-right: 5px;
}
.grievance-chat-wrapper .send-link{
cursor: pointer;
}
.contactUsTable thead th:last-child{
  width: 45%;
}
.contactUsTable .link-orange-text{
  cursor: pointer;
  font-size: 13px;
}

.slider-td .switch {
  position: relative;
  display: inline-block;
  width: 50px;
    height: 22px;
    margin-bottom: 0px;
}

.slider-td .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-td .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-td .slider:before {
  position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    /* bottom: 4px; */
    top: 3px;
    background-color: white;
    transition: .4s;
}

.slider-td input:checked + .slider {
  background-color: #c4d2f4;
}

.slider-td input:focus + .slider {
  box-shadow: 0 0 1px #c4d2f4;
}

.slider-td input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider-td .slider.round {
  border-radius: 34px;
}

.slider-td .slider.round:before {
  border-radius: 50%;
}
.userListTable thead th:last-child{
  z-index: 9;
}
.grievanceTable thead th:last-child{
  z-index: 99;
}
.startup-listing-wrapper .rectangle-card{
  width: 93px !important;
}
.startup-listing-wrapper .rectangle-card-selected{
  width: 93px !important;
}
.small-font-badge{
width: 80% !important;
}
.nopadding{
  padding: 0 !important;
  margin: 0 !important;
}
#carouselExampleIndicators{
  width: 80%;
  margin: auto;
}
#carouselExampleIndicators .carousel-item{
  max-height: 200px !important;
}
#carouselExampleIndicators .carousel-control-prev-icon {
  background-image: url(../images/LeftArrow.svg) !important;
}
.galleryCarousel #carouselExampleIndicators .carousel-control-prev {
  left: -60px !important;
}
.galleryCarousel #carouselExampleIndicators .carousel-control-next {
  right: -60px !important;
}
#carouselExampleIndicators .carousel-control-prev {
  left: -80px !important;
}
#carouselExampleIndicators .carousel-control-next {
  right: -80px !important;
}
#carouselExampleIndicators .carousel-control-next-icon {
  background-image: url(../images/RightArrow.svg) !important;
}
.portfolio-container .about-heading{
  padding-top: 65px;
  height: 175px;
}
.portfolio-detail-container .mainImage{
  margin-left: -10px;
}
.statusTable .table-heading{
  line-height: 0px;
  background-color: #f8f7f7;
}
.statusTable td{
  line-height: 20px;
  background-color: #f8f7f7;
}

/* switch button for Quaterly Review*/



.custom-td1 {
  /* min-width: 180px !important; */
  padding-left: 10px;
}
.slider-td1 .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
  margin-bottom: 0px;
}
.slider-td1 .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider-td1 .slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e26640;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-td1 .slider1:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  /* bottom: 4px; */
  top: 3px;
  background-color: white;
  transition: .4s;
}

.slider-td1 input:checked + .slider1 {
  background-color: #68e568;
}

.slider-td1 input:focus + .slider1 {
  box-shadow: 0 0 1px #c4d2f4;
}



.slider-td1 input:checked + .slider1:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}



/* Rounded sliders */
.slider-td1 .slider1.round1 {
  border-radius: 34px;
}



.slider-td1 .slider1.round1:before {
  border-radius: 50%;
}



.qrtable .table-heading1 {
  text-align: center;
  color: #333333;
  padding: 25px 10px;
  font-family: "Open Sans";
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 17px;
  position: sticky;
  top: 0; 
  z-index: 2;
}
.qrtable .table-heading1{
border-bottom: none!important;
  border-top: none!important;
}
.qrtable td{
  border-top: none!important;
  border-bottom: none!important;
}
.quaterly_rectangle_card {
  height: 123px;
  width: 115px;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
}



.quaterly_rectangle_card_selected {
  height: 123px;
  width: 115px;
  border-radius: 20px;
  background-color: #f7f8ff;
  /* border: 1px solid #dddddd; */
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  padding: 1px;
}


.quaterly_rectangle_card_new {
  /* height: 131px; */
  width: 155px;
  border-radius: 14px;
  background-color: #f7f8ff;
  border: 1px solid #dddddd;
  margin: 6px;
  padding-top: 10px;
  text-align: center;
  cursor: pointer;
  /* padding-top: 10px;
  width: 155px; */

  padding: 20px;
  width: 280px;
}


.card-text-incubator_new {
  color: #4d4a4a;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  cursor: default;
}

.incubator-numeric-black_new {
  color: #2b3f89;
  font-family: "Open Sans";
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 43px;
  text-align: center;
  line-height: 43px;
  cursor: default;
}








